import {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  useNavigate,
} from "react-router-dom";

import routes from "../routes.js";
import {
  login as authLogin,
  logout as authLogout,
} from "../services/auth.js";
import {
  addUser,
} from '../services/users.js';

const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
  
  const [user, setUser] = useState(userData);
  const navigate = useNavigate();

  const login = async (username, password, remember) => {
    let user = await authLogin(username, password, remember);
    setUser(user);
  };

  const requestLogin = () => {
      setUser(null)
      navigate(routes.login, {
        replace: true,
        state: {
          redirect: location.pathname,
        }
      });
  }

  const logout = async () => {
    try {
      await authLogout();
    } catch (error) { }
    setUser(null);
    navigate(routes.login, { replace: true });
  };

  const signup = async({name, surname, username, email, token, password}) => {
    let user = await addUser({name, surname, username, email, token, password});
    if (!!user.username)
      setUser(user);
    return user;
  };

  const value = useMemo(
    () => ({
      user,
      setUser,
      login,
      requestLogin,
      logout,
      signup,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
