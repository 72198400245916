
import dayjs from 'dayjs';
import 'dayjs/locale/es';

import { 
    useEffect,
    useState,
} from "react";

import {
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { 
    DatePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';

import {
    CustomTextField,
    Title,
    Message,
    Signature,
    DataTreatment
} from "../../components";
import * as DateUtils from "../../services/date.js";

export const renderFormElement = (t, data, element, setter) => {
    if (!data)
        return;
    
    if (element.type === 'select') {
        const [value, setValue ] = useState(data[element.key] || element.options[0].value);
        const handleChange = (event) => {
            setValue(event.target.value);
            setter(element.key)(event.target.value);
        };
        useEffect(() => setValue(data[element.key]), [data[element.key]])
        return (
            <FormControl fullWidth>
                <InputLabel id="select-label">{t(element.label)}</InputLabel>
                <Select labelId="select-label" id="demo-simple-select" defaultValue="" value={value || ''} label={t(element.label)} onChange={handleChange}>
                    {element.options.map((entry, idx) => (
                        <MenuItem value={entry.value} key={`${element.key}_${entry.value}_${idx}`}>{entry.text}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
    
    if (element.type === 'date') {
        const handleChange = (value) => {
            setter(element.key)(DateUtils.dayjsToDb(value));
        };
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                <DatePicker label={t(element.label)} value={DateUtils.dbToDayjs(data[element.key])} onChange={handleChange} />
            </LocalizationProvider>
        );
    }
    
    if (element.type === 'bool') {
        const [checked, setChecked ] = useState(false);
        const handleChange = (event) => {
            setChecked(event.target.checked);
            setter(element.key)(event.target.checked)
        };
        useEffect(() => setChecked(data[element.key] || false), [data[element.key]])
        return (
                <FormControlLabel control={<Switch checked={checked} onChange={handleChange} />} label={t(element.label)} />
        );
    }
    
    if (element.type === 'title') {
        return (<Title>{t(element.label)}</Title>);
    }
    
    if (element.type === 'message') {
            return (<Message>{t(element.label)}</Message>);
    }

    if (element.type === 'data_treatment') {
        return (<DataTreatment></DataTreatment>);
    }

    if (element.type === 'signature') {
        return (<Signature>{data[element.key]}</Signature>);
    }

    if (element.type === 'divider') {
        return (<Divider sx={{ mb:2}}/>);
    }

    if (element.type === 'text') {
        return (
            <CustomTextField value={data[element.key] || ''} setValue={setter(element.key)} label={t(element.label)} disabled={!!element.disabled} fullWidth />
        );
    }
}
