const get = (key) => {
    return localStorage.getItem(key);
}

const set = (key, value) => {
    // localStorage.removeItem(key);
    return localStorage.setItem(key, value);
}

const clear = (key) => {
    return localStorage.removeItem(key);
}

export const cache = {
    get,
    set,
    clear,
};