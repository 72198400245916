import Resizer from "react-image-file-resizer";

import {
  useEffect,
  useState,
} from 'react';
import {
  useTranslation,
} from 'react-i18next';
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Tooltip,
} from '@mui/material';
import {
  Save as SaveIcon,
} from '@mui/icons-material';

import {
  CustomTextField,
  MainFrame,
  PasswordTextField,
  Title,
} from "../components";
import {
  validateEmail,
  validatePassword,
  validatePhone,
  validateText,
} from '../services/validator.js'
import {
  updateUser,
} from '../services/users.js'
import {
    Avatar,
} from "../components/Avatar.js";
import { useAuth } from "../hooks/useAuth";

export function Profile() {
  const {t} = useTranslation();
  const auth = useAuth();
  const [avatar, setAvatar] = useState(auth.user.avatar);
  const [name, setName] = useState(auth.user.name);
  const [nameValid, setNameValid] = useState("");
  const [surname, setSurname] = useState(auth.user.surname);
  const [surnameValid, setSurnameValid] = useState("");
  const [phone, setPhone] = useState(auth.user.phone);
  const [phoneValid, setPhoneValid] = useState("");
  const [email, setEmail] = useState(auth.user.email);
  const [emailValid, setEmailValid] = useState("");

  const [profession, setProfession] = useState(auth.user.profession);
  const [professionValid, setProfessionValid] = useState("");
  const [service, setService] = useState(auth.user.service);
  const [serviceValid, setServiceValid] = useState("");

  const [passwordCustomError, setPasswordCustomError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState('');
  const [password2, setPassword2] = useState('');
  const [password2Valid, setPassword2Valid] = useState('');

  const [valid, setValid] = useState(false);

  const validatePasswords = () => {
      setPasswordCustomError(passwordValid && password2Valid && password !== password2 ? t('validator.password_match') : '');
  };
  useEffect(validatePasswords, [password, passwordValid, password2, password2Valid]);
  useEffect(() => {
    setValid(nameValid && surnameValid && phoneValid && emailValid && professionValid && serviceValid && passwordValid && password2Valid && passwordCustomError === '')
  }, [nameValid, surnameValid, phoneValid, emailValid, passwordValid, password2Valid, passwordCustomError]);

  const handleSubmit = async (event) => 
  {
      event.preventDefault();
      let user = {
        uuid: auth.user.uuid,
        name,
        surname,
        phone,
        email,
        profession,
        service,
      };
      if (avatar.startsWith('data:image'))
        user.avatar = avatar;
      if (!!password && !!password2 && passwordCustomError === '') {
        user.password = password;
      }
      try {
        user = await updateUser(user);
        auth.setUser(user);
      } catch (e) {
        if (e.message == '404')
          setShowAlert(t('alert.update_user_failed'));
        else if (e.message === '401')
          auth.requestLogin();
      }
  }

  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 64, 64, 'JPEG', 90, 0,
    uri => {
      resolve(uri);
    }, 'base64' );
  });

  const handleFileUpload = async (e) => {
    if (!e.target.files)
      return;
    const file = e.target.files[0];
    const image = await resizeFile(file);
    setAvatar(image);

    // const reader = new FileReader();
    // reader.onload = (evt) => {
    //   if (!evt?.target?.result)
    //     return;
    //   console.error("evt?.target?.result", evt?.target?.result)
    // };
    // reader.readAsBinaryString(file);
  }

  const [showAlert, setShowAlert] = useState('');
  const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway')
          return;
      setShowAlert('');
  };

  return (
    <MainFrame>
      <Paper component="form" onSubmit={handleSubmit} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
              <input accept="image/*" id="icon-button-file" multiple type="file" onChange={handleFileUpload} hidden/>
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <Tooltip title={t('tooltip.update_avatar')} >
                    <>
                      <Avatar alt={`${auth?.user?.name} ${auth?.user?.surname}`} src={auth?.user?.avatar} sx={{ width: 64 , height: 64  }}/>
                    </>
                  </Tooltip>
                </IconButton>
              </label>
            </Box>
        <Title>{t('label.general_info')}</Title>
        <Grid container spacing={2} sx={{ pb: 2 }}>
          <Grid item xs={12} sm={6} >
            <CustomTextField value={name} setValue={setName} setIsValid={setNameValid} validator={validateText({name: t('label.name').toLowerCase()})} label={t('label.name')} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField value={surname} setValue={setSurname} setIsValid={setSurnameValid} validator={validateText({name: t('label.surname').toLowerCase()})} label={t('label.surname')} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField value={phone} setValue={setPhone} setIsValid={setPhoneValid} validator={validatePhone({allowEmpty: true})} label={t('label.phone')} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField value={email} setValue={setEmail} setIsValid={setEmailValid} validator={validateEmail({allowEmpty: true})} label={t('label.email')} fullWidth />
          </Grid>
        </Grid>
        <Title>{t('label.professional_info')}</Title>
        <Grid container spacing={2} sx={{ pb: 2 }}>
          <Grid item xs={12} sm={6}>
            <CustomTextField value={profession} setValue={setProfession} setIsValid={setProfessionValid} validator={validateText({allowEmpty: true, name: t('label.profession').toLowerCase()})} label={t('label.profession')} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField value={service} setValue={setService} setIsValid={setServiceValid} validator={validateText({allowEmpty: true, name: t('label.service').toLowerCase()})} label={t('label.service')} fullWidth />
          </Grid>
        </Grid>
        <Title>{t('label.access')}</Title>
        <Grid container spacing={2} sx={{ pb: 2 }}>
          <Grid item xs={12} >
            <CustomTextField value={auth.user.username} label={t('label.username')} disabled autoComplete="username" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordTextField value={password} setValue={setPassword} setIsValid={setPasswordValid} validator={validatePassword({allowEmpty: true})} label={t('label.password_simple')} autoComplete="new-password" fullWidth/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordTextField value={password2} setValue={setPassword2} setIsValid={setPassword2Valid} validator={validatePassword({allowEmpty: true})} label={t('label.repeat_password')} autoComplete="new-password" fullWidth/>
          </Grid>
        </Grid>
        <Button variant="outlined" endIcon={<SaveIcon />} type="submit" sx={{ mt: 2 }} disabled={!valid}>{t('button.save')}</Button>
      </Paper>
      <Snackbar open={!!showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
              {showAlert}
          </Alert>
      </Snackbar>
    </MainFrame>
  );
}