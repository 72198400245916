import { 
    useOutletContext 
} from "react-router-dom";
import {
    useTranslation,
} from 'react-i18next';

import {
    Box,
    Container,
    Grid,
} from '@mui/material';

import {
    ChildData,
} from './ChildData.js'
import {
    renderFormElement,
} from './renderFormElement.js'

export function Sociodemographic({}) {

    const { t } = useTranslation();
    const {sociodemographic, setSociodemographic} = useOutletContext();
    
    const userData = [
        {label: 'label.userData', key: 'userData', type: 'title'},
        {label: 'label.birthday', test: "user", key: 'birthday', type: 'date', width: 6},
        {label: 'label.profession', key: 'profession', type: 'text', width: 6},
        {label: 'label.address', key: 'address', type: 'text', width: 12},
        {label: 'label.phone', key: 'phone', type: 'text', width: 6},
        {label: 'label.email', key: 'email', type: 'text', width: 6},
        {label: 'label.withPartner', key: 'withPartner', type: 'bool', width: 6},
        {label: 'label.administrativeSituation', key: 'administrativeSituation', type: 'select', options: [
            {value: "irregular", text: t('administrativeSituation.irregular')}, 
            {value: "temporaryResidence", text: t('administrativeSituation.temporaryResidence')}, 
            {value: "permanentResidence", text: t('administrativeSituation.permanentResidence')}, 
            {value: "asylumSeeker", text: t('administrativeSituation.asylumSeeker')},             
            {value: "other", text: t('administrativeSituation.other')}
        ], width: 6},
        {label: 'label.offspring', key: 'offspring', type: 'bool', width: 6},
        {label: 'label.offspringWithAggressor', key: 'offspringWithAggressor', type: 'bool', width: 6}
    ];

    const setValueHandler = (key) => {
        return (value) => {
            sociodemographic[key] = value;
            setSociodemographic(JSON.parse(JSON.stringify(sociodemographic)));
        };
    }

    return (
        <>
            <Box sx={{ border: 0, borderColor: (theme) => theme.palette.grey[100], }} >
                {/* <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}> */}
                    <Grid container spacing={2} sx={{ pb: 2 }}>
                        {userData.map((entry) => (
                            <Grid item xs={12} sm={entry.width || 12 } key={entry.key} >
                                {renderFormElement(t, sociodemographic, entry, setValueHandler)}
                            </Grid>
                        ))}
                    </Grid>
                {/* </Container> */}
            </Box>
        </>
    );
}