import {
    post,
    request,
    remove,
    serverUrl,
} from './request.js'

export const fixAvatar = (user) => {
    if (!user.avatar)
        return user;
    return Object.assign(user, {avatar: `${serverUrl}${user.avatar}`});
}

export const getUsers = async function (query) {
    let res = await request(`/users?${Object.entries(query).map(([key, value]) => value ? `${key}=${value}` : '').filter(x => !!x).join('&')}`);
    if (res.status === 200) {
        let users = await res.json();
        return users.map(fixAvatar);
    }
    throw new Error(res.status);
};

export const addUser = async function (user) {
    let res = await post('/users', user);
    if (res.status === 201) {
        let user = await res.json();
        return fixAvatar(user);
    }
    throw new Error(res.status);
};

export const deleteUser = async function (uuid) {
    let res = await remove(`/users/${uuid}`);
    if (res.status === 200) {
        let user = await res.json();
        return user;
    }
    throw new Error(res.status);
};

export const updateUser = async function (user) {
    let res = await post(`/users/${user.uuid}`, user);
    if (res.status === 200) {
        let user = await res.json();
        return fixAvatar(user);
    }
    throw new Error(res.status);
};