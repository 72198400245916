import {
  Navigate,
  useLocation,
  useOutlet,
} from "react-router-dom";

import {
  useAuth,
} from "../../hooks/useAuth";
import routes from "../../routes.js";

export const HomeLayout = () => {
  
  const location = useLocation();
  const { user } = useAuth();
  const outlet = useOutlet();

  if (user) {
    let redirect = location.state?.redirect || routes.home;
    return <Navigate to={redirect} state={{redirect: ''}} replace />;
  }

  return (
    <div>
      {outlet}
    </div>
  );
};
