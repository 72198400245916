import { 
    Fragment,
    useState,
    useEffect,
} from "react";
import { 
    useOutletContext,
} from "react-router-dom";
import {
    useTranslation,
} from 'react-i18next';
import {
    Alert,
    Box,
    Grid,
    Snackbar,
} from '@mui/material';

import {
    useAuth,
} from "../../hooks/useAuth.js";
import { 
    getDossiersFiles, 
} from "../../services/dossiers.js";
import { 
    File,
} from "../../components/File.js";

export function Files() {

    const { dossier, showDragArea } = useOutletContext();
    const { t } = useTranslation();
    const auth = useAuth();

    const [files, setFiles] = useState([]);

    let loadFilesTimer = null;
    const loadFiles = () => {
        if (loadFilesTimer) {
            clearTimeout(loadFilesTimer);
            loadFilesTimer = null;
        }
        loadFilesTimer = setTimeout(async () => {
            if (!dossier || !dossier.uuid)
                return setFiles([]);
            try {
                let data = await getDossiersFiles(dossier.uuid);
                setFiles(data);
            } catch (e) {
                if (e.message == '404')
                    setShowAlert(t('alert.get_files_failed'));
                else if (e.message == '401')
                    auth.requestLogin();
            };
        }, 100);
    };

    useEffect(() => loadFiles(), []);
    useEffect(() => loadFiles(), [dossier]);
    useEffect(() => { if (!showDragArea) loadFiles(); }, [showDragArea]);

    const [showAlert, setShowAlert] = useState('');
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setShowAlert('');
    };

    const handleDelete = async (files) => {
        console.error("todo")
    }

    return (
        <>
            <Box sx={{ border: 0, borderColor: (theme) => theme.palette.grey[100], }} >
                <Fragment>
                    <Grid container spacing={1}>
                        {files.map((file, index) => {
                            return (
                                <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2}>
                                    <File file={file} onDeleteFile={file => handleDelete(file)}></File>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Fragment>
            </Box>
            <Snackbar open={!!showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                    {showAlert}
                </Alert>
            </Snackbar>
        </>
    );
}