import { 
    useState,
} from 'react'; 
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import {
    useTranslation,
} from 'react-i18next';

import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    AppBar as MuiAppBar,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { 
    styled,
} from '@mui/material/styles';

import routes from "../routes";
import { 
    drawerWidth,
} from "../settings.js";
import {
    Avatar,
} from "./Avatar.js";
import { useAuth } from "../hooks/useAuth";


const StyledBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'open',})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export function AppBar({ keepOpen, drawerOpen, toggleDrawer })
{
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [anchorElUser, setAnchorElUser] = useState(null);
    const auth = useAuth();

    const menuItems = [{
        text: t('section.profile'),
        link: routes.profile,
      },
      {
        text: t('section.logout'),
        link: routes.logout,
      }
    ];

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = (to) => {
        setAnchorElUser(null);
        if (!!to)
            navigate(to, { replace: true });
    };

    return (
        <StyledBar position="absolute" open={(drawerOpen && !keepOpen)}>
            <Toolbar sx={{ pr: '24px', }} >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...((drawerOpen || keepOpen) && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                    {t('application')}
                </Typography>

                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title={t('tooltip.actions')}>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar alt={`${auth?.user?.name} ${auth?.user?.surname}`} src={auth?.user?.avatar} />
                    </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                        open={Boolean(anchorElUser)}
                        onClose={() => handleCloseUserMenu()}
                    >
                    {menuItems.map((setting) => (
                        <MenuItem key={setting.text} onClick={() => handleCloseUserMenu(setting.link)} >
                            <Typography textAlign="center">{setting.text}</Typography>
                        </MenuItem>
                    ))}
                    </Menu>
                </Box>
            </Toolbar>
        </StyledBar>   
    );
};