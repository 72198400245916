import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  defer
} from 'react-router-dom';

import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';

import routes from "./routes.js";

import {
  Blank,
  Dossiers,
  Dossier,
  DossierFiles,
  DossierHealth,
  DossierInterventions,
  DossierLegal,
  DossierMain,
  DossierSociodemographic,
  DossierSocialServices,
  DossierSecurityServices,
  DossierPoliceServices,
  DossierRGPD,
  E404,
  Interventions,
  Main,
  Profile,
  Login,
  Logout,
  Signup,
  Users,
  Suggestions,
  Suggestion,
} from "./pages";

import {
  AuthLayout,
  HomeLayout,
  ProtectedLayout,
} from "./components/layouts";

import {
  ResolverProvider,
} from "./hooks/useResolver.js";

import {
  ensureLogin,
} from "./services/auth.js";
import { purple } from '@mui/material/colors';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout/>}
      loader={() => defer({ userPromise: ensureLogin().catch(() => { return null;}) })}
    >
      <Route element={<ProtectedLayout />}>
        <Route exact path={routes.home} element={<Dossiers />} />
        <Route path={routes.dossiers} element={<Dossiers />} />
        <Route path={routes.dossier} element={<Dossier />}>
          <Route index element={<DossierMain />} />
          <Route index path={routes.dossier_main} element={<DossierMain />} />
          <Route path={routes.dossier_sociodemographic} element={<DossierSociodemographic />} />
          <Route path={routes.dossier_social} element={<DossierSocialServices />} />
          <Route path={routes.dossier_security} element={<DossierSecurityServices />} />
          <Route path={routes.dossier_police} element={<DossierPoliceServices />} />
          <Route path={routes.dossier_legal} element={<DossierLegal />} />
          <Route path={routes.dossier_health} element={<DossierHealth />} />
          <Route path={routes.dossier_rgpd} element={<DossierRGPD />} />
          <Route path={routes.dossier_interventions} element={<DossierInterventions />} />
          <Route path={routes.dossier_files} element={<DossierFiles />} />
          <Route path="*" element={<E404 />} />
        </Route> 
        <Route path={routes.interventions} element={<Interventions />} />
        <Route path={routes.profile} element={<Profile />} />
        <Route path={routes.logout} element={<Logout />} />
        <Route path={routes.users} element={<Users />} />
        <Route path={routes.suggestions} element={<Suggestions />} />
        <Route path={routes.suggestion} element={<Suggestion />}></Route>
      </Route>
      <Route element={<HomeLayout />}>
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.signup} element={<Signup />} />
      </Route>
      <Route path="*" element={<E404 />} />
    </Route>
  )
);

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: purple[800]
    }
  }
});

export function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <ResolverProvider>
        <RouterProvider router={router} />
      </ResolverProvider>
    </ThemeProvider>
  );
}
