import {
    Avatar as MuiAvatar,
} from '@mui/material';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 4) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const Avatar = (props) => {
    const {children, alt = '', src = '', sx = {}, ...innerProps} = props;
    let newSrc = !src ? "/error" : src;
    let newAlt = alt.toUpperCase()[0];
    let newSx = Object.assign({}, sx, {bgcolor: stringToColor(alt)});
    return (
        <MuiAvatar sx={newSx} alt={newAlt} src={newSrc} {...innerProps}>
            {children}
        </MuiAvatar>
    );
}