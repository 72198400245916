import { 
    useEffect,
    useState,
} from "react";
import {
    useParams,
} from 'react-router-dom';
import {
    useTranslation,
} from 'react-i18next';
import {
    Avatar,
    Button,
    CssBaseline,
    Grid,
    Box,
    Typography,
    Container,
} from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import {
    validateEmail,
    validatePassword,
    validateText,
    validateUsername,
  } from '../services/validator.js'

import {
    useAuth,
} from "../hooks/useAuth";
import { 
    Copyright,
    CustomTextField,
    PasswordTextField,
} from "../components";


export function Signup() 
{
    const {t} = useTranslation();
    const auth = useAuth();
    const { token } = useParams();

    const queryParameters = new URLSearchParams(window.location.search)

    const [usernameError, setUsernameError] = useState('');
    const [username, setUsername] = useState('');
    const [usernameValid, setUsernameValid] = useState(false);
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(false);
    const [surname, setSurname] = useState('');
    const [surnameValid, setSurnameValid] = useState(false);

    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [tokenValid, setTokenValid] = useState('');

    const [passwordCustomError, setPasswordCustomError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);
    const [password2, setPassword2] = useState('');
    const [password2Valid, setPassword2Valid] = useState(false);

    const [valid, setValid] = useState(false);

    const validatePasswords = () => {
        setPasswordCustomError(passwordValid && password2Valid && password !== password2 ? t('validator.password_match') : '');
    };
    useEffect(validatePasswords, [password, password2, passwordValid, password2Valid]);
    useEffect(() => {
        setValid(usernameValid && nameValid && surnameValid && emailValid && !tokenValid && passwordValid && password2Valid && passwordCustomError === '')
    }, [usernameValid, nameValid, surnameValid, tokenValid, emailValid, passwordValid, password2Valid, passwordCustomError]);
    
    const handleSubmit = async (event) => 
    {
        event.preventDefault();
        try {
            await auth.signup({username, name, surname, email, token, password});
        } catch (error) {
            if (error.message === '409') {
                setUsernameError(t('validator.username_exists'));
            } if (error.message === '401')
                setTokenValid(t('validator.invalid_token'));
        }
    };
  
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('title.sign_up')}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CustomTextField value={username} setValue={setUsername} setIsValid={setUsernameValid} validator={validateUsername()} error={usernameError} required fullWidth label={t('label.username')} autoComplete="given-name" autoFocus/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomTextField value={name} setValue={setName} setIsValid={setNameValid} validator={validateText({name: t('label.name').toLowerCase()})} required fullWidth label={t('label.name')} autoComplete="given-name" autoFocus/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomTextField value={surname} setValue={setSurname} setIsValid={setSurnameValid} validator={validateText({name: t('label.surname').toLowerCase()})} required fullWidth label={t('label.surname')} autoComplete="family-name"/>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField value={email} setValue={setEmail} setIsValid={setEmailValid} validator={validateEmail()} required fullWidth label={t('label.email')}/>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField value={token} error={tokenValid} required fullWidth label="Token" disabled={true}/>
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordTextField value={password} setValue={setPassword} setIsValid={setPasswordValid} validator={validatePassword()} error={passwordCustomError} required fullWidth label={t('label.password_simple')} autoComplete="new-password" id="newpassword"/>
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordTextField value={password2} setValue={setPassword2} setIsValid={setPassword2Valid} validator={validatePassword()} error={passwordCustomError} required fullWidth label={t('label.repeat_password')} autoComplete="new-password" id="confirmnewpassword"/>
                        </Grid>
                    </Grid>
                    <Button type="submit" fullWidth variant="contained" disabled={!valid} sx={{ mt: 3, mb: 2 }}>
                        {t('button.sign_up')}
                    </Button>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
  }
  