import {
    useEffect,
    useState,
} from 'react';
import { 
    useOutletContext 
} from "react-router-dom";
import {
    useTranslation,
} from 'react-i18next';

import {
    Box,
    Container,
    Grid,
} from '@mui/material';

import {
    renderFormElement,
} from './renderFormElement.js'

export function Health() {

    const { t } = useTranslation();
    const {healthServices, setHealthServices} = useOutletContext();
    const [privateNameDisabled, setPrivateNameDisabled] = useState(false);

    const userData = [
        {label: 'health.insuranceType', key: 'insuranceType', type: 'select', options: [
            {value: "sergas", text: t('health.sergas')}, 
            {value: "private", text: t('health.private')}], width: 6},
        {label: 'health.privateName', key: 'privateName', type: 'text', disabled: privateNameDisabled, width: 12},
        {type: 'divider', key: 'divider_0'},
        {label: 'health.primaryCareReferralDoctor', key: 'primaryCareReferralDoctor', type: 'text', width: 12},
        {label: 'label.healthServicesExplanation', key: 'healthServicesExplanation', type: 'title'},
        {label: 'label.saveDossierRemember', key: 'saveDossierRemember', type: 'title'},
    ];

    useEffect(() => {
        setPrivateNameDisabled(healthServices['insuranceType'] !== 'private');
    }, [healthServices]);

    const setValueHandler = (key) => {
        return (value) => {
            healthServices[key] = value;
            setHealthServices(JSON.parse(JSON.stringify(healthServices)));
        };
    }

    return (
        <>
            <Box sx={{ border: 0, borderColor: (theme) => theme.palette.grey[100], }} >
                <Grid container spacing={2} sx={{ pb: 2 }}>
                    {userData.map((entry) => (
                        <Grid item xs={12} sm={entry.width || 12 } key={entry.key} >
                            {renderFormElement(t, healthServices, entry, setValueHandler)}
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
}