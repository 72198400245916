import dayjs from 'dayjs';
import 'dayjs/locale/es';

// ReactJS imports
import {
    useState,
    useEffect,
} from 'react';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import {
    useTranslation,
} from 'react-i18next';

// MUI imports
import {
    Alert,
    Box,
    Button,
    Grid,
    Modal,
    Snackbar,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { 
    DatePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';
import { 
    AdapterDayjs,
} from '@mui/x-date-pickers/AdapterDayjs';
import {
    ContactPage as ContactPageIcon,
    Close as CloseIcon,
    Delete as DeleteIcon,
    RestartAlt as RestartAltIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

// Components
import {
    CustomTextField,
} from "./index.js";
import {
    cache,
} from "../services/cache.js";
import * as DateUtils from "../services/date.js";
import {
    useAuth, 
} from "../hooks/useAuth";
import { 
    useResolver,
} from "../hooks/useResolver.js";
import routes from "../routes";
import {
    addIntervention,
    deleteIntervention,
    getIntervention,
    updateIntervention,
} from "../services/interventions.js";
import Prompt from "../components/Prompt.js";

function prune(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'object')
            prune(obj[key]);
        else if (!obj[key])
           delete obj[key];
    }
}

export function Intervention(props) {

    const {uuid, setUUID} = props;

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { user, requestLogin } = useAuth();
    const { resolve, resolveUser } = useResolver();

    const now = DateUtils.dayjsToDb(dayjs().startOf('day'));

    const [interventionSignature, setInterventionSignature] = useState('');
    const [changed, setChanged] = useState(false);

    const [intervention, setIntervention] = useState({});
    const [date, setDate] = useState(now);
    const [professional, setProfessional] = useState('');
    const [dossier, setDossier] = useState('');
    const [service, setService] = useState('');
    const [content, setContent] = useState('');

    const [flags, setFlags] = useState({});

    const loadNewIntervention = async () => {
        let template = {
            date: now, 
            professional: user.uuid, 
            dossier: props.dossier || '',
            service: props.service || '', 
            content: ''
        };
        await resolve("users", [user.uuid]);
        prune(template);
        setInterventionSignature(JSON.stringify(template))
        setIntervention(template);
    }

    const loadExistingIntervention = async () => {
        try {

            let url = routes.getInterventionRoute(uuid);
            let cached = cache.get(url);
            let res = await getIntervention(uuid);
            prune(res);
            setInterventionSignature(JSON.stringify(res));
            let data = !!cached ? JSON.parse(cached) : res;
            await resolve("users", [data.professional]);
            setIntervention(data);
        } catch (e) {
            setIntervention({});
            if (e.message == '404')
                setShowAlert(t('alert.get_intervention_failed'));
            else if (e.message === '401')
                requestLogin();
        }
    }

    const loadIntervention = () => {
        if (!!uuid && uuid !== 'new') 
            loadExistingIntervention();
        else
            loadNewIntervention();
    };

    const loadStates = () => {
        setDate(intervention.date || now);
        setProfessional(intervention.professional || '');
        setDossier(intervention.dossier || '');
        setService(intervention.service || '');
        setContent(intervention.content || '');
    };

    const updateCache = () => {
        let cached = {
            uuid: uuid !== 'new' ? uuid : '',
            date,
            professional,
            dossier,
            service,
            content,
        };
        prune(cached);
        if (!!interventionSignature && JSON.stringify(cached) !== interventionSignature) {
            // console.error("set cache", JSON.stringify(cached), interventionSignature)
            setChanged(true);
            cache.set(routes.getInterventionRoute(uuid), JSON.stringify(cached));
        } else {
            // console.error("clear cache", JSON.stringify(cached), interventionSignature);
            setChanged(false);
            cache.clear(routes.getInterventionRoute(uuid));
        }
    };

    const updateFlags = () => {
        let flags = {};
        if (location.pathname.includes('/dossier/'))
            flags.inUsersTab = true;
        flags.canDelete = user.role === "admin" && uuid !== 'new';
        setFlags(flags);
    };

    useEffect(() => loadStates(), [intervention]);
    useEffect(() => loadIntervention(), []);
    useEffect(() => loadIntervention(), [uuid]);
    useEffect(() => updateCache(), [date, professional, dossier, service, content, interventionSignature]);
    useEffect(() => updateFlags(), [location.pathname, uuid]);

    const handleSaveIntervention = async () => {
        intervention.date = date;
        intervention.professional = professional;
        intervention.dossier = dossier;
        intervention.service = service;
        intervention.content = content;
        try {
            if (uuid === 'new') {
                let res = await addIntervention(intervention);
                prune(res);
                cache.clear(routes.getInterventionRoute(uuid));
                setInterventionSignature(JSON.stringify(res));
                setIntervention(res);
                setUUID(res.uuid);
            } else {
                let res = await updateIntervention(intervention);
                prune(res);
                cache.clear(routes.getInterventionRoute(uuid));
                setInterventionSignature(JSON.stringify(res));
                setIntervention(res);
            }
        } catch (e) {
            if (e.message == '404')
                setShowAlert(uuid === 'new' ? t('alert.add_intervention_failed') : t('alert.update_intervention_failed'));
            else if (e.message == '401')
                auth.requestLogin();
        }
    };

    const handleCloseIntervention = () => {
        if (changed && !window.confirm(t('prompt.unsaved_intervention')))
            return;
        if (!!setUUID)
            setUUID();
    };

    const handleDeleteIntervention = () => {
        setDeletedIntervention({uuid});
    };

    const handleDeleteInterventionReject = () => {
        setDeletedIntervention({});
    };

    const handleDeleteInterventionAccept = async () => {
        setDeletedIntervention({});
        try {
            await deleteIntervention(uuid);
            if (!!setUUID)
                setUUID();   
        } catch (e) {
            if (e.message == '404')
                setShowAlert(t('alert.delete_intervention_failed'));
            else if (e.message == '401')
                auth.requestLogin();
        }
    };

    const [deletedIntervention, setDeletedIntervention] = useState({});

    const handleRestart = () => {
        cache.clear(routes.getInterventionRoute(uuid));
        loadIntervention();
    };

    const handleGotoDossier = () => {
        navigate(routes.getDossierRoute(intervention.dossier), { replace: true });
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.innerWidth - 40,
        height: window.innerHeight - 40,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      };
    const resize = () => {
        // let buttons = document.getElementById('interventionButtons');
        let editor = document.getElementById('interventionEditor');
        let mainWindow = document.getElementById('mainWindow');
        let h = 20;
        if (!!editor)
            h = mainWindow.getBoundingClientRect().height  - editor.getBoundingClientRect().y;
        if (!!editor)
            document.getElementById('interventionEditor').style.height = `${h - 32}px`;
    };

    useEffect(() => resize(), [window.innerHeight]);
    resize();

    const [showAlert, setShowAlert] = useState('');
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setShowAlert('');
    };
      
    return (
        <>
        <Prompt when={changed && !!uuid} message={t('prompt.unsaved_intervention')} />
        <Modal open={!!uuid}>
            <Box id="mainWindow" sx={ style }>
                <Grid container spacing={2} sx={{ pb: 2 }}>
                    <Grid item xs={12}>    
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                            <DatePicker label={t('label.date')} value={DateUtils.dbToDayjs(date)} onChange={value => setDate(DateUtils.dayjsToDb(value))} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>    
                        <CustomTextField disabled value={resolveUser(professional)} setValue={setProfessional} label={t('label.professional')} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>    
                        <CustomTextField disabled value={service} setValue={setService} label={t('label.service')} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="interventionEditor" value={content} onChange={(e) => setContent(e.target.value)} multiline fullWidth sx={{height: 'fit-content'}}/>
                    </Grid>
                </Grid>

                <SpeedDial
                    ariaLabel=""
                    sx={{ position: 'absolute', bottom: 16, right: 16 }}
                    icon={<SpeedDialIcon />}
                >   
                    {<SpeedDialAction 
                        icon={<CloseIcon />}
                        tooltipTitle={t('tooltip.close')}
                        onClick={handleCloseIntervention} /> }
                        
                    {<SpeedDialAction 
                        icon={<SaveIcon />}
                        tooltipTitle={t('tooltip.save')}
                        disabled={!changed}
                        onClick={handleSaveIntervention} /> }

                    {<SpeedDialAction 
                        icon={<RestartAltIcon />}
                        tooltipTitle={t('tooltip.reset')}
                        disabled={!changed}
                        onClick={handleRestart} /> }
                        
                    {!flags.inUsersTab && <SpeedDialAction 
                        icon={<ContactPageIcon/>}
                        tooltipTitle={t('tooltip.goto_dossier')}
                        onClick={handleGotoDossier} />}

                    {flags.canDelete && <SpeedDialAction 
                        icon={<DeleteIcon/>}
                        tooltipTitle={t('tooltip.delete')}
                        onClick={handleDeleteIntervention} />}
                </SpeedDial>
            </Box>
        </Modal>
        <Dialog
            open={!!deletedIntervention.uuid}
            onClose={handleDeleteInterventionReject}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('dialog.delete_intervention_title')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('dialog.delete_intervention_desc')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteInterventionReject}>{t('dialog.button_no')}</Button>
                <Button onClick={handleDeleteInterventionAccept} autoFocus>{t('dialog.button_yes')}</Button>
            </DialogActions>
        </Dialog>
        <Snackbar open={!!showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                {showAlert}
            </Alert>
        </Snackbar>
        </>
    );
}