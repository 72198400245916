import {
    useState,
} from 'react';
import {
    useTranslation,
} from 'react-i18next';

import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

import {
    Card,
    CardHeader,
    CardContent,
    Collapse,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    MoreVert as MoreVertIcon,
 } from '@mui/icons-material';

import {
    red,
} from '@mui/material/colors';

import {
    PersonRemove as PersonRemoveIcon,
} from '@mui/icons-material';

import {
    useAuth,
} from "../hooks/useAuth";
import {
    Avatar,
} from "./Avatar.js";

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export const User = ({user, onDeleteUser}) => {
    const {t} = useTranslation();
    const auth = useAuth();

    const [cardExpanded, setCardExpanded] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const menuOpen = Boolean(menuAnchorEl);

    const handleMenuOpen = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };
    
    const handleRemoveUser = () => {
        if (!!onDeleteUser)
            onDeleteUser(user);
        setMenuAnchorEl(null);
    };
    
    const handleExpandClick = () => {
        setCardExpanded(!cardExpanded);
    };
  
    return (
        <>
            <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                    <MenuItem onClick={handleRemoveUser}>
                        <ListItemIcon><PersonRemoveIcon/></ListItemIcon>
                        {t('action.remove_user')}
                    </MenuItem>
                </Menu>
            </Paper>
            <Card>
                <CardHeader 
                    avatar={<Avatar aria-label="recipe" alt={`${user?.name} ${user?.surname}`} src={user?.avatar} ></Avatar>}
                    action={
                        <>
                            {auth.user.role === 'admin' && <IconButton aria-label="settings" onClick={handleMenuOpen}><MoreVertIcon /></IconButton>}
                            <ExpandMore expand={cardExpanded} onClick={handleExpandClick} aria-expanded={cardExpanded} aria-label="show more">
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </>
                    }
                    title={`${user.surname}, ${user.name}`}
                    subheader={user.username}
                    sx={{padding: 1}}
                />
                <Collapse in={cardExpanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Table size="small">
                            <TableBody>
                                    <TableRow>
                                        <TableCell>{t('label.email')}</TableCell>
                                        <TableCell><Link to={`mailto:${user.email}`}>{user.email}</Link></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('label.phone')}</TableCell>
                                        <TableCell><Link to={`tel:${user.phone}`}>{user.phone}</Link></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('label.profession')}</TableCell>
                                        <TableCell>{user.profession}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('label.service')}</TableCell>
                                        <TableCell>{user.service}</TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Collapse>
            </Card>
        </>
    );
};