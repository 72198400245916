import { 
    useOutletContext 
} from "react-router-dom";
import {
    useTranslation,
} from 'react-i18next';

import {
    Box,
    Grid,
} from '@mui/material';

import {
    renderFormElement,
} from './renderFormElement.js'

export function Main({}) {

    const { t } = useTranslation();
    const {main, setMain} = useOutletContext();
    
    const userData = [
        {label: 'label.dataRange', key: 'dataRange', type: 'select', options: [
            {value: 'under18', text: t('dataRange.under18')}, 
            {value: 'under24', text: t('dataRange.under24')}, 
            {value: 'under44', text: t('dataRange.under44')}, 
            {value: 'under64', text: t('dataRange.under64')}, 
            {value: 'under80', text: t('dataRange.under80')}, 
            {value: 'over80', text: t('dataRange.over80')}], width: 12},

        {label: 'label.maritalStatus', key: 'maritalStatus', type: 'select', options: [
            {value: 'single', text: t('maritalStatus.single')}, 
            {value: 'married', text: t('maritalStatus.married')}, 
            {value: 'divorced', text: t('maritalStatus.divorced')}, 
            {value: 'widow', text: t('maritalStatus.widow')}], width: 12},

        {label: 'label.childrenLink', key: 'childrenLink', type: 'select', options: [
            {value: 'fullCustody', text: t('childrenLink.fullCustody')}, 
            {value: 'sharedCustody', text: t('childrenLink.sharedCustody')}, 
            {value: 'withoutCustody', text: t('childrenLink.withoutCustody')}, 
            {value: 'guardianship', text: t('childrenLink.guardianship')}, 
            {value: 'other', text: t('childrenLink.other')}], width: 12},

        {label: 'label.coexistenceNucleus', key: 'coexistenceNucleus', type: 'select', options: [
            {value: 'nuclearFamily', text: t('coexistenceNucleus.nuclearFamily')}, 
            {value: 'alone', text: t('coexistenceNucleus.alone')}, 
            {value: 'inCouple', text: t('coexistenceNucleus.inCouple')}, 
            {value: 'singleParent', text: t('coexistenceNucleus.singleParent')}, 
            {value: 'oryxFamily', text: t('coexistenceNucleus.oryxFamily')}, 
            {value: 'other', text: t('coexistenceNucleus.other')}], width: 12},
            
        {label: 'label.incomeLevel', key: 'incomeLevel', type: 'select', options: [
            {value: 'noIncome', text: t('incomeLevel.noIncome')}, 
            {value: 'below200', text: t('incomeLevel.below200')}, 
            {value: 'below400', text: t('incomeLevel.below400')}, 
            {value: 'below600', text: t('incomeLevel.below600')}, 
            {value: 'below800', text: t('incomeLevel.below800')}, 
            {value: 'below1000', text: t('incomeLevel.below1000')}, 
            {value: 'below1200', text: t('incomeLevel.below1200')}, 
            {value: 'over1200', text: t('incomeLevel.over1200')}], width: 12},

        {label: 'label.sourceOfIncome', key: 'sourceOfIncome', type: 'select', options: [
            {value: 'noIncome', text: t('sourceOfIncome.noIncome')}, 
            {value: 'wageEarner', text: t('sourceOfIncome.wageEarner')}, 
            {value: 'ownAccount', text: t('sourceOfIncome.ownAccount')}, 
            {value: 'SEPEbenefit', text: t('sourceOfIncome.SEPEbenefit')}, 
            {value: 'INSSbenefit', text: t('sourceOfIncome.INSSbenefit')}, 
            {value: 'generalFinancialBenefits', text: t('sourceOfIncome.generalFinancialBenefits')}, 
            {value: 'specificFinancialBenefitVVX', text: t('sourceOfIncome.specificFinancialBenefitVVX')},
            {value: 'other', text: t('sourceOfIncome.other')}], width: 12},

        {label: 'label.workSituation', key: 'workSituation', type: 'select', options: [
            {value: 'unemployed', text: t('workSituation.unemployed')}, 
            {value: 'longTermUnemployed', text: t('workSituation.longTermUnemployed')}, 
            {value: 'employed', text: t('workSituation.employed')}, 
            {value: 'pensioner', text: t('workSituation.pensioner')}, 
            {value: 'inactive', text: t('workSituation.inactive')}, 
            {value: 'student', text: t('workSituation.student')}], width: 12},

        {label: 'label.formalEducationalLevel', key: 'formalEducationalLevel', type: 'select', options: [
            {value: 'noStudies', text: t('formalEducationalLevel.noStudies')}, 
            {value: 'basicStudies', text: t('formalEducationalLevel.basicStudies')}, 
            {value: 'secondaryStudies', text: t('formalEducationalLevel.secondaryStudies')}, 
            {value: 'superiorStudies', text: t('formalEducationalLevel.superiorStudies')}, 
            {value: 'higherSudiesAbroadWithoutApproval', text: t('formalEducationalLevel.higherSudiesAbroadWithoutApproval')}], width: 12},

        {label: 'label.typeOfViolenceDetected', key: 'typeOfViolenceDetected', type: 'title'},
        {label: 'typeOfViolenceDetected.psychological', key: 'psychological', type: 'bool', width: 6},
        {label: 'typeOfViolenceDetected.physics', key: 'physics', type: 'bool', width: 6},
        {label: 'typeOfViolenceDetected.economic', key: 'economic', type: 'bool', width: 6},
        {label: 'typeOfViolenceDetected.sexual', key: 'sexual', type: 'bool', width: 6},
        {label: 'typeOfViolenceDetected.vicar', key: 'vicar', type: 'bool', width: 6},
        {label: 'typeOfViolenceDetected.social', key: 'social', type: 'bool', width: 6},
        {label: 'typeOfViolenceDetected.institutional', key: 'institutional', type: 'bool', width: 6},
        {label: 'typeOfViolenceDetected.obstetrician', key: 'obstetrician', type: 'bool', width: 6},
        {type: 'divider', key: 'divider_1'},

        {label: 'label.intersectionality', key: 'intersectionality', type: 'title'},
        {label: 'intersectionality.foreignOrigin', key: 'foreignOrigin', type: 'bool', width: 6},
        {label: 'intersectionality.ethnicMinority', key: 'ethnicMinority', type: 'bool', width: 6},
        {label: 'intersectionality.withADisability', key: 'withADisability', type: 'bool', width: 6},
        {label: 'intersectionality.homeless', key: 'homeless', type: 'bool', width: 6},
        {label: 'intersectionality.ruralWoman', key: 'ruralWoman', type: 'bool', width: 6},
        {label: 'intersectionality.institutionalized', key: 'institutionalized', type: 'bool', width: 6},
        {label: 'intersectionality.other', key: 'other', type: 'bool', width: 6},
        {type: 'divider', key: 'divider_2'},

        {label: 'label.bondWithAggressor', key: 'bondWithAggressor', type: 'select', options: [
            {value: 'partner', text: t('bondWithAggressor.partner')}, 
            {value: 'ex_partner', text: t('bondWithAggressor.ex_partner')}, 
            {value: 'familyEnvironment', text: t('bondWithAggressor.familyEnvironment')}, 
            {value: 'other', text: t('bondWithAggressor.other')},
            {value: 'stranger', text: t('bondWithAggressor.stranger')}, 
            {value: 'group', text: t('bondWithAggressor.group')} 
        ],
        width: 12},
    ];

    
    const setValueHandler = (key) => {
        return (value) => {
            main[key] = value;
            setMain(JSON.parse(JSON.stringify(main)));
        };
    }

    return (
        <>
            <Box sx={{ border: 0, borderColor: (theme) => theme.palette.grey[100], }} >
                <Grid container spacing={2} sx={{ pb: 2 }}>
                    {userData.map((entry) => (
                        <Grid item xs={12} sm={entry.width || 12 } key={entry.key} >
                            {renderFormElement(t, main, entry, setValueHandler)}
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
}