import {
    Suspense,
} from "react";
import {
    Await,
    defer,
} from "react-router-dom";

import {
    Alert,
    LinearProgress,
    Typography,
} from "@mui/material";

import {
    MainFrame
} from '../components';

import {
    useAuth,
} from "../hooks/useAuth";

export function Logout() {
    const auth = useAuth();

    return (
        <MainFrame>
            <Suspense fallback={<LinearProgress />}>
                <Await
                    resolve={defer((auth.logout()))}
                    errorElement={<Alert severity="error">Something went wrong!</Alert>}
                    children={(user) => (
                        <Typography variant="body2" color="text.secondary" align="center">
                            Goodbye {auth.user ? auth.user.username : ''}
                        </Typography>
                    )}
                />
            </Suspense>
        </MainFrame>
    );
}
