import {
    Fragment,
    useEffect,
    useState,
} from 'react';
import {
    useTranslation,
} from 'react-i18next';

import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Grid,
    Paper,
    Snackbar,
 } from '@mui/material'
import { 
    ManageSearch as ManageSearchIcon,
} from "@mui/icons-material";

import { 
    User,
} from "./User.js";

import {
    CustomTextField,
    Title,
} from "../components";

import {
    deleteUser,
    getUsers,
} from "../services/users.js";
import {
    useAuth,
} from "../hooks/useAuth";

export function UserList() {

    const auth = useAuth();
    const {t} = useTranslation();

    const [users, setUsers] = useState([]);
    const [deletedUser, setDeletedUser] = useState('');

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [profession, setProfession] = useState("");
    const [service, setService] = useState("");

    const handleSearch = async () => {
        try {
            let res = await getUsers({name, surname, profession, service});
            setUsers(res);
        } catch (e) {
            if (e.message == '404')
                setShowAlert(t('alert.get_users_failed'));
            else if (e.message === '401')
                auth.requestLogin();
        }
    };

    const handleDelUser = async () => {
        try {
            await deleteUser(deletedUser.uuid);
            setUsers(await getUsers({}));
        } catch (e) {
            if (e.message == '404')
                setShowAlert(t('alert.delete_user_failed'));
            else if (e.message === '401')
                auth.requestLogin();
        }
        setDeletedUser({});
    };
  
    const handleClose = () => {
        setDeletedUser({});
    };

    const handleDelete = async (user) => {
        setDeletedUser(user);
    };

    const [showAlert, setShowAlert] = useState('');
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setShowAlert('');
    };

    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Dialog open={!!deletedUser.uuid} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {t('dialog.delete_user_title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('dialog.delete_user_desc', {user: deletedUser.username})}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('dialog.button_no')}</Button>
                    <Button onClick={handleDelUser} autoFocus>{t('dialog.button_yes')}</Button>
                </DialogActions>
            </Dialog>
            <Fragment>
                <Title>{t('label.professionals_list')}</Title>
                <Grid container spacing={2} sx={{ pb: 2 }}>
                    <Grid item xs={12} sm={6} >
                        <CustomTextField value={name} setValue={setName} label={t('label.name')} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField value={surname} setValue={setSurname} label={t('label.surname')} fullWidth />
                    </Grid> 
                    <Grid item xs={12} sm={6}>
                        <CustomTextField value={profession} setValue={setProfession} label={t('label.profession')} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField value={service} setValue={setService} label={t('label.service')} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', '& > :not(style)': { m: 1 } }}>
                            <Fab color="primary" disabled={false && !searchAllowed} onClick={handleSearch}>
                                <ManageSearchIcon />
                            </Fab>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    {users.map((user, index) => {
                        return (
                            <Grid item key={index} xs={12}>
                                <User user={user} onDeleteUser={user => handleDelete(user)}></User>
                            </Grid>
                        )
                    })}
                </Grid>
            </Fragment>
            <Snackbar open={!!showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                    {showAlert}
                </Alert>
            </Snackbar>
        </Paper>
    );
}