import i18next from 'i18next';

import {
    useEffect,
    useState,
} from 'react';
import {
    Alert,
    FormControl,
    IconButton,
    Snackbar,
} from '@mui/material';
import {
    ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import {
    CustomTextField
} from './CustomTextField.js'

export function CopyTextField(props) {

    const {buttons = [], ...innerProps} = props;

    const [open, setOpen] = useState(false);
    const [valueInternal, setValueInternal] = useState(props.value);

    useEffect(() => {
        setValueInternal(props.value);
    }, [props.value]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpen(false);
    };
    
    const handleCopy = () => {
        navigator.clipboard.writeText(valueInternal);
        setOpen(true);
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    buttons.push(
        <IconButton
            aria-label='copy'
            onClick={handleCopy}
            onMouseDown={handleMouseDown}
            edge='end'
            key='cp-text-field-btn'
            disabled={valueInternal.length === 0 }
        >
            <ContentCopyIcon />
        </IconButton>
    );

    return (
        <FormControl variant="outlined" fullWidth>
            <CustomTextField {...innerProps} buttons={buttons} setValue={setValueInternal} fullWidth/>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {i18next.t('alert.message_copied')}
                    </Alert>
                </Snackbar>
        </FormControl>
    );
}