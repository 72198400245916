import i18next from 'i18next';

export const validatePhone = ({allowEmpty, errorMsg} = {}) => {
    return (value) => {
        if (allowEmpty && !value)
            return '';
        if (!value.match(/^[69]{1}[0-9]{8}$/))
            return i18next.t('validator.phone');
        return '';
    };
}

export const validateEmail = ({allowEmpty} = {}) => {
    return (value) => {
        if (allowEmpty && !value)
            return '';
        if (!value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
            return i18next.t('validator.email');
        return '';
    };
}

export const validatePassword = ({allowEmpty} = {}) => {
    return (value) => {
        if (allowEmpty && !value)
            return '';
        if (!value.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/))
            return i18next.t('validator.password');
        return '';
    };
}

export const validateText = ({allowEmpty, name} = {}) => {
    return (value) => {
        if (allowEmpty && !value)
            return '';
        if (!value)
            return i18next.t('validator.text', {name});
        return '';
    };
}

export const validateUsername = ({allowEmpty} = {}) => {
    return (value) => {
        if (allowEmpty && !value)
            return '';
            if (!value.match(/^[A-Za-z][A-Za-z0-9_\.]{7,29}$/))
            return i18next.t('validator.username');
        return '';
    };
}