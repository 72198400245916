import dayjs from 'dayjs';
import 'dayjs/locale/es';

import { 
    Fragment,
    useState,
    useEffect,
} from "react";
import { 
    useOutletContext,
} from "react-router-dom";
import {
    useTranslation,
} from 'react-i18next';
import {
    Alert,
    Box,
    Snackbar,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from '@mui/material';

import { 
    getInterventions,
} from "../../services/interventions.js";
import {
    useResolver,
} from "../../hooks/useResolver.js";
import * as DateUtils from "../../services/date.js";
import {
    useAuth,
} from "../../hooks/useAuth";

export function Interventions() {

    const {dossier, interventionUUID, setInterventionUUID} = useOutletContext();
    const { t } = useTranslation();
    const auth = useAuth();
    const { resolve, resolveUser} = useResolver();

    const [interventions, setInterventions] = useState([]);

    let loadInterventionsTimer = null;
    const loadInterventions = () => {
        if (loadInterventionsTimer) {
            clearTimeout(loadInterventionsTimer);
            loadInterventionsTimer = null;
        }
        loadInterventionsTimer = setTimeout(async () => {
            if (!dossier || !dossier.uuid)
                return setInterventions([]);
            try {
                let data = await getInterventions({dossier: dossier.uuid});
                await resolve("users", data.map(x => x.professional));
                setInterventions(data);
            } catch (e) {
                if (e.message == '404')
                    setShowAlert(t('alert.get_interventions_failed'));
                else if (e.message == '401')
                    auth.requestLogin();
            };
        }, 100);
    };

    useEffect(() => loadInterventions(), []);
    useEffect(() => loadInterventions(), [dossier, interventionUUID]);

    
    const [showAlert, setShowAlert] = useState('');
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setShowAlert('');
    };

    return (
        <>
            <Box sx={{ border: 0, borderColor: (theme) => theme.palette.grey[100], }} >
                <Fragment>
                    <Table size="small"  sx={{ mt: 2, mb: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('label.date')}</TableCell>
                                <TableCell>{t('label.service')}</TableCell>
                                <TableCell>{t('label.professional')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {interventions.map((row) => (
                            <TableRow key={row.uuid} onClick={() => setInterventionUUID(row.uuid)}>
                                <TableCell>{DateUtils.dbToDayjs(row.date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>{row.service}</TableCell>
                                <TableCell>{resolveUser(row.professional)}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </Fragment>
            </Box>
            <Snackbar open={!!showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                    {showAlert}
                </Alert>
            </Snackbar>
        </>
    );
}