import {
    Box,
    Container,
    Toolbar,
} from '@mui/material';

import {
    Copyright,
} from "./Copyright";

export default function MainFrame({children})
{
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                { children }
                <Copyright sx={{ pt: 4 }} />
            </Container>
        </Box>
    );
}