
import {
    Fragment,
    useEffect,
    useState,
} from 'react';
import {
    useTranslation,
} from 'react-i18next';
import {
    useNavigate,
} from 'react-router-dom';
import {
    Paper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from '@mui/material';

import { 
    dbToDayjs,
} from "../services/date.js";
import routes from "../routes";

import {
    MainFrame,
} from "../components";
import {  
    getSuggestions,
} from "../services/suggestions.js";
import {
    useAuth,
} from "../hooks/useAuth";

export function Suggestions() {
    
    const auth = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const [suggestions, setSuggestions] = useState([]);

    const loadSuggestions = async () => {
        try {
            let data = await getSuggestions({});
            setSuggestions(data);
        } catch (e) {
            if (e.message === '401')
                auth.requestLogin();
        }
    }

    const load = () => {
        loadSuggestions();
    }

    useEffect(() => load(), []);
    
    const handleSaveSuggestion = () => {

    };

    const handleAddSuggestion = () => {

    };

    return (
        <MainFrame>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                <Fragment>
                    <Table size="small"  sx={{ mt: 2, mb: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>{t('label.date')}</TableCell>
                                <TableCell>{t('label.incidence')}</TableCell>
                                {/* <TableCell>{t('label.action')}</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {suggestions.map((row, index) => (
                            <TableRow key={row.uuid} onClick={() => navigate(routes.getSuggestionRoute(row.uuid))}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{dbToDayjs(row.date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>{row.incidence}</TableCell>
                                {/* <TableCell>{row.action}</TableCell> */}
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </Fragment>
            </Paper>
        </MainFrame>
    );
}