import {
    useState,
} from 'react';
import {
    IconButton,
} from '@mui/material';
import {
    Visibility,
    VisibilityOff,
} from '@mui/icons-material';
import { 
    CustomTextField,
} from "./CustomTextField";

export function PasswordTextField(props) {

    const {type, buttons = [], ...innerProps} = props;

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    buttons.push(
        <IconButton
            aria-label="toggle password visibility"
            key='show-pwd-icon'
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
        >
            {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
    );

    return (
        <CustomTextField 
            {...innerProps} 
            type={showPassword ? 'text' : 'password'}
            buttons={buttons} 
        />
    );
}