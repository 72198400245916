import * as React from 'react';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';

import Card from '@mui/material/Card';
import { Button, CardActionArea, CardActions } from '@mui/material';

import {
  Delete as DeleteIcon,
} from '@mui/icons-material';

function Signature(props) {
  const {variant = "subtitle1"} = props;
  const signatureCanvasRef = useRef(null);

  const clearCanvas = () => {
    signatureCanvasRef.current.clear();
  };

  const saveSignatureData = () => {
    const data = signatureCanvasRef.current.toData();
  };
  
  const loadSignatureData = (savedData) => {
    signatureCanvasRef.current.fromData(savedData);
  };

  return (
    <Card variant="outlined">
      <SignatureCanvas ref={signatureCanvasRef} penColor='purple' canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />  
      <CardActions>
        <Button onClick={clearCanvas} startIcon={<DeleteIcon />}>Borrar Firma</Button>
      </CardActions>
    </Card>
  );
}

Signature.propTypes = {
  children: PropTypes.node,
};

export default Signature;