import {
  useTranslation,
} from 'react-i18next';
import {
  Box,
  Typography,
} from '@mui/material/';
import Link from '@mui/material/Link';

export function Copyright() 
{
  const { t } = useTranslation();

    return (
      <Box sx={{mt: 2}}>
        <Typography variant="body2" color="text.secondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://maxapp.gal/">
            {t('company')}
          </Link>{' '}
          {new Date().getFullYear()}
        </Typography>
      </Box>
    );
  }