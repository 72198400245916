
import {
    createContext,
    useContext,
    useMemo,
} from "react";

import { getUsers } from "../services/users.js";
import { getInterventions } from "../services/interventions.js";
import { getDossiers } from "../services/dossiers.js";

const ResolverContext = createContext();

export const ResolverProvider = ({ children, userData }) => {
  
    const MAX_SIZE = 1000;

    let list = [];
    let database = {};
    
    const getUUIDUri = (collection, uuid) => {
        return `data://${collection}/${uuid}`;
    }

    const getElementUri = (collection, element) => {
        return `data://${collection}/${element.uuid}`;
    }
    
    const addToDatabase = (collection, element) => {
        if (list.length >= MAX_SIZE)
            list.splice(0, list.length - MAX_SIZE).forEach(uri => delete database[uri]);
        const uri = getElementUri(collection, element);
        database[uri] = element;
        list.push(uri);
    }
    
    const getFromDatabase = (collection, uuid) => {
        return database[getUUIDUri(collection, uuid)];
    }
    
    const resolve = async (collection, uuids) => {

        // Remove dupplicates
        uuids = [...new Set(uuids)];

        let missingUUIDs = [];
        let foundUUIDs = [];
     
        // Check if exists in cache
        uuids.forEach(uuid => {
            let res = getFromDatabase(collection, uuid);
            if (res)
                foundUUIDs.push(res);
            else
                missingUUIDs.push(uuid);
    
        });
    
        // Query server
        if (missingUUIDs.length > 0) {
            let data;
            switch (collection) {
                case "users":
                    data = await getUsers({uuid: `in(${missingUUIDs.join(',')})`});
                    break;
                case "intervention":
                    data = await getInterventions({uuid: `in(${missingUUIDs.join(',')})`});
                    break;
                case "dossiers":
                    data = await getDossiers({uuid: `in(${missingUUIDs.join(',')})`});
                    break;
            }
            if (data) {
                data.forEach(el => addToDatabase(collection, el));
            }
            foundUUIDs = foundUUIDs.concat(data);
        }
    
        // Format output
        let res = {}
        foundUUIDs.forEach(data => res[data.uuid] = data);
        return res;
    }
    
    const resolveUser = (uuid) => {
        let user = getFromDatabase("users", uuid);
        if (!user || (!user.name && !user.surname))
            return uuid;
        return `${user.surname}, ${user.name}`;
    }

    const resolveDossier = (uuid) => {
        let user = getFromDatabase("dossiers", uuid);
        if (!user || (!user.name && !user.surname))
            return uuid;
        return `${user.surname}, ${user.name}`;
    }
  
    const value = {
        resolve,
        resolveUser,
        resolveDossier,
    };
  
    return <ResolverContext.Provider value={value}>{children}</ResolverContext.Provider>;
};

export const useResolver = () => {
    return useContext(ResolverContext);
};