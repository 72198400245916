import {
    useEffect,
    useState,
    Fragment,
} from 'react';
import {
    useTranslation,
} from 'react-i18next';
import {
    useNavigate,
} from 'react-router-dom';
import {
    Alert,
    Box,
    Fab,
    Grid,
    Paper,
    Snackbar,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import {
    ManageSearch as ManageSearchIcon,
} from '@mui/icons-material';

import routes from "../routes";
import {
    CustomTextField,
    MainFrame,
    Title,
} from "../components";
import {  
    cache,
} from "../services/cache.js";
import {  
    getDossiers,
} from "../services/dossiers.js";
import {
    useAuth,
} from "../hooks/useAuth";

export function Dossiers() {
    
    const auth = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [uuid, setUuid] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [ni, setNi] = useState("");
    const [searchAllowed, setSearchAllowed] = useState(false);

    useEffect(() => {
        let cached = cache.get('/dossiers');
        if (!!cached) {
            let data = JSON.parse(cached);
            setName(data.name);
            setSurname(data.surname);
            setNi(data.ni);
        }
    }, []);

    useEffect(() => {
        setSearchAllowed(!!uuid || !!name || !!surname || !!ni);
        cache.set('/dossiers', JSON.stringify({
            uuid,
            name,
            surname,
            ni,
        }));
    }, [uuid, name, surname, ni]);

    const [dossiers, setDossiers] = useState([]);

    const handleSearch = async (e) => {
        e.preventDefault();
        try {
            let dossiers = await getDossiers({uuid, name, surname, ni});
            setDossiers(dossiers);
        } catch (e) {
            if (e.message == '404')
                setShowAlert(t('alert.get_dossiers_failed'));
            else if (e.message === '401')
                auth.requestLogin();
        }
    };

    const [showAlert, setShowAlert] = useState('');
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setShowAlert('');
    };

    return (
        <MainFrame>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                    <Title>{t('label.dossier_search')}</Title>
                    <Grid container spacing={2} sx={{ pb: 2 }}>
                        <Grid item xs={12} >
                            <CustomTextField value={uuid} setValue={setUuid} label={t('label.uuid')} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <CustomTextField value={name} setValue={setName} label={t('label.name')} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomTextField value={surname} setValue={setSurname} label={t('label.surname')} fullWidth />
                        </Grid> 
                        <Grid item xs={12} sm={6}>
                            <CustomTextField value={ni} setValue={setNi} label={t('label.ni')} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', '& > :not(style)': { m: 1 } }}>
                                <Fab color="primary" disabled={false && !searchAllowed} onClick={handleSearch}>
                                    <ManageSearchIcon />
                                </Fab>
                            </Box>
                        </Grid>
                    </Grid>
                <Fragment>
                    <Table size="small"  sx={{ mt: 2, mb: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>{t('label.name')}</TableCell>
                                <TableCell>{t('label.surname')}</TableCell>
                                <TableCell>{t('label.ni')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {dossiers.map((row, index) => (
                            <TableRow key={row.uuid} onClick={() => navigate(routes.getDossierRoute(row.uuid))}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.surname}</TableCell>
                                <TableCell>{row.ni}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </Fragment>
            </Paper>
            <Snackbar open={!!showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                    {showAlert}
                </Alert>
            </Snackbar>
        </MainFrame>
    );
}