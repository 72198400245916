import {
    post,
    request,
    remove,
    serverUrl,
    upload,
} from './request.js'

export const getDossiers = async (query) => {
    let res = await request(`/dossiers/?${Object.entries(query).map(([key, value]) => value ? `${key}=${value}` : '').filter(x => !!x).join('&')}`);
    if (res.status === 200)
        return await res.json();
    throw new Error(res.status);
};

export const getDossier = async (uuid) => {
    let res = await request(`/dossiers/${uuid}`);
    if (res.status === 200)
        return await res.json();
    throw new Error(res.status);
};

export const addDossier = async (dossier) => {
    let res = await post('/dossiers', dossier);
    if (res.status === 201)
        return await res.json();
    throw new Error(res.status);
};

export const deleteDossier = async (uuid) => {
    let res = await remove(`/dossiers/${uuid}`);
    if (res.status === 200)
        return await res.json();
    throw new Error(res.status);
};

export const updateDossier = async (dossier) => {
    let res = await post(`/dossiers/${dossier.uuid}`, dossier);
    if (res.status === 200)
        return await res.json();
    throw new Error(res.status);
};

export const getDossiersFiles = async (uuid) => {
    let res = await request(`/dossiers/${uuid}/files`);
    if (res.status !== 200)
        throw new Error(res.status);
    let collection = await res.json();
    return collection.map(file => Object.assign(file, {url: `${serverUrl}${file.url}`}));
};

export const addDossierFile = async (uuid, file) => {
    let res = await upload(`/dossiers/${uuid}/files/${file.name}`, file);
    if (res.status === 201)
        return await res.json();
    throw new Error(res.status);
};