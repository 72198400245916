import {
  unstable_useBlocker as useBlocker,
} from 'react-router-dom'

function Prompt(props) {
  const {when, exception} = props;
    
  useBlocker((data) => {
    const next = data.nextLocation.pathname;
    if (exception && exception.test(next))
      return false;
    if (when)
      return !window.confirm(props.message);
    return false
  });

  return (
    <div key={when}/>
  )
}

export default Prompt