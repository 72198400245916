import {
  useState,
} from 'react';
import {
  Navigate,
  useLocation,
  useOutlet,
} from "react-router-dom";

import {
  Box,
  CssBaseline,
  useMediaQuery,
} from '@mui/material';
import { 
  useTheme,
} from '@mui/material/styles';

import {
  useAuth
} from "../../hooks/useAuth";

import routes from "../../routes";

import {
  AppBar,
  Drawer,
} from "../";

export const ProtectedLayout = () => {
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const { user } = useAuth();
  const outlet = useOutlet();
  const location = useLocation();

  if (!user)
    return <Navigate to={routes.login} state={{redirect: location.pathname}}/>;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar keepOpen={!isMobile} drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      <Drawer keepOpen={!isMobile} drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      {outlet}
    </Box>
  );
};