import {
    useEffect,
    useState,
} from 'react';
import {
    useParams,
    Outlet,
    Link,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import {
    useTranslation,
} from 'react-i18next';
import {
    Alert,
    Button,
    Grid,
    LinearProgress,
    Paper,
    Snackbar,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Tabs,
    Tab,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    AddComment as AddCommentIcon,
    Delete as DeleteIcon,
    Save as SaveIcon,
    RestartAlt as RestartAltIcon,
    FileUpload as FileUploadIcon,
} from '@mui/icons-material';
import { DropzoneDialog } from 'mui-file-dropzone';

import {
    CopyTextField,
    CustomTextField,
    MainFrame,
    Title,
} from "../components";
import Prompt from '../components/Prompt.js'
import routes from "../routes";
import {
    cache,
} from "../services/cache.js";
import {
    addDossier,
    addDossierFile,
    deleteDossier,
    getDossier,
    updateDossier,
} from "../services/dossiers.js";
import {
    useAuth, 
} from "../hooks/useAuth";
import { 
    Intervention,
} from '../components';

function prune(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'object')
            prune(obj[key]);
        else if (!obj[key])
           delete obj[key];
    }
}

export function Dossier() {

    const auth = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const { uuid } = useParams();

    const [dossierSignature, setDossierSignature] = useState('');
    const [changed, setChanged] = useState(false);

    const [dossier, setDossier] = useState({});
    const [main, setMain] = useState({});
    const [sociodemographic, setSociodemographic] = useState({});
    const [socialServices, setSocialServices] = useState({});
    const [securityServices, setSecurityServices] = useState({});
    const [policeServices, setPoliceServices] = useState({});
    const [rgpd, setRGPD] = useState({});
    const [legalServices, setLegalServices] = useState({});
    const [healthServices, setHealthServices] = useState({});
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [ni, setNi] = useState("");

    const loadStates = () => {
        setName(dossier.name || '');
        setSurname(dossier.surname || '');
        setNi(dossier.ni || '');
        setMain(dossier.main || {});
        setSociodemographic(dossier.sociodemographic || {});
        setSocialServices(dossier.socialServices || {});
        setSecurityServices(dossier.securityServices || {});
        setPoliceServices(dossier.policeServices || {});
        setRGPD(dossier.rgpd || {});
        setLegalServices(dossier.legalServices || {});
        setHealthServices(dossier.healthServices || {});
    };
    
    const loadDossier = () => {
        if (uuid !== 'new') {
            let route = routes.getDossierRoute(uuid);
            let cached = cache.get(route);
            getDossier(uuid)
                .then(res => {
                    prune(res);
                    setDossierSignature(JSON.stringify(res));
                    if (!!cached)
                        setDossier(JSON.parse(cached));
                    else
                        setDossier(res);
                })
                .catch((e) => {
                    if (e.message == '404')
                        setShowAlert(t('alert.get_dossier_failed'));
                    else if (e.message === '401')
                        auth.requestLogin();
                    setDossier({});
                })
        } else {
            let template = {
                main: {},
                sociodemographic: {},
                socialServices: {},
                securityServices: {},
                policeServices: {},
                legalServices: {},
                healthServices: {},
                rgpd: {}
            };
            setDossier(template);
            setDossierSignature(JSON.stringify(template));
        }
    };

    const updateCache = () => {
        let cached = {
            uuid: uuid !== 'new' ? uuid : '',
            name,
            surname,
            ni,
            main,
            sociodemographic,
            socialServices,
            legalServices,
            healthServices,
            securityServices,
            policeServices,
            rgpd
    };
        prune(cached);
        if (!!dossierSignature && JSON.stringify(cached) !== dossierSignature) {
            // console.error("cache", JSON.stringify(cached), "vs", dossierSignature)
            setChanged(true);
            cache.set(routes.getDossierRoute(uuid), JSON.stringify(cached));
        } else {
            // console.error("clear cache", JSON.stringify(cached), !!dossierSignature)
            setChanged(false);
            cache.clear(routes.getDossierRoute(uuid));
        }
    };

    useEffect(() => loadStates(), [dossier]);
    useEffect(() => loadDossier(), [])
    useEffect(() => loadDossier(), [uuid])
    useEffect(() => updateCache(), [name, surname, ni, main, sociodemographic, socialServices, legalServices, healthServices, securityServices, policeServices, rgpd, dossierSignature]);

    const handleSaveDossier = async (e) => {
        e.preventDefault();
        dossier.name = name;
        dossier.surname = surname;
        dossier.ni = ni;
        dossier.main = main;
        dossier.sociodemographic = sociodemographic;
        dossier.socialServices = socialServices;
        dossier.legalServices = legalServices;
        dossier.healthServices = healthServices;
        dossier.securityServices = securityServices;
        dossier.policeServices = policeServices;
        dossier.rgpd = rgpd;
        let sub = "";
        [
            routes.dossier_sociodemographic,
            routes.dossier_social,
            routes.dossier_security,
            routes.dossier_police,
            routes.dossier_health,
            routes.dossier_rgpd,
            routes.dossier_interventions,
            routes.dossier_files
        ].every(route => {
            if (location.pathname.endsWith(route)) {
                sub = route;
                return false;
            }
            return true
        });

        try {
            if (uuid === 'new') {
                let res = await addDossier(dossier);
                prune(res);
                cache.clear(routes.getDossierRoute(uuid));
                setDossierSignature(JSON.stringify(res));
                navigate(routes.getDossierRoute(res.uuid, sub), { replace: true });
            } else {
                let res = await updateDossier(dossier);
                prune(res);
                cache.clear(routes.getDossierRoute(uuid));
                setDossierSignature(JSON.stringify(res));
                setDossier(res);
            }
        } catch (e) {
            if (e.message == '404')
                setShowAlert(uuid === 'new' ? t('alert.add_dossier_failed') : t('alert.update_dossier_failed'));
            else if (e.message == '401')
                auth.requestLogin();
        }
    };

    const [interventionUUID, setInterventionUUID] = useState();

    const handleAddIntervention = (e) => {
        e.preventDefault();
        setInterventionUUID('new');
    };

    const handleDeleteDossier = () => {
        setDeletedDossier({uuid});
    };

    const handleDeleteDossierReject = () => {
        setDeletedDossier({});
    };

    const handleDeleteDossierAccept = async () => {
        setDeletedDossier({});
        try {
            await deleteDossier(uuid);
            navigate(routes.dossiers, { replace: true });  
        } catch (e) {
            if (e.message == '404')
                setShowAlert(t('alert.delete_dossier_failed'));
            else if (e.message == '401')
                auth.requestLogin();
        }
    };

    const [deletedDossier, setDeletedDossier] = useState({});
    const [showDragArea, setShowDragArea] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(-1);

    const handleRestart = () => {
        cache.clear(routes.getDossierRoute(uuid));
        loadDossier();
    };

    const handleStartUpload = () => {
        setShowDragArea(true);
    }

    const handleSaveDragArea = async (files) => {
        if (!files || !files.length)
            return;
        setUploadProgress(0);
        await files.forEach(async (file, index) => {
            setUploadProgress(index*(100/files.length));
            await addDossierFile(dossier.uuid, file)
        });
        setUploadProgress(-1);
        setShowDragArea(false);
    }

    const [flags, setFlags] = useState({});
    const [service, setService] = useState('');
    const [tabIndex, setTabIndex] = useState(0);

    const updateFlags = () => {
        let dossierExists = uuid !== 'new';
        let showSave = true;
        let showReset = true;
        let showUpload = false;
        let showDelete = auth.user.role === "admin" && dossierExists;
        let service = "";
        
        if (location.pathname.endsWith(routes.dossier_sociodemographic)) {
            setTabIndex(1);
        } else if (location.pathname.endsWith(routes.dossier_social)) {
            service = 'Social';
            setTabIndex(2);
        } else if (location.pathname.endsWith(routes.dossier_security)) {
            service = 'Seguridade';
            setTabIndex(3);
        } else if (location.pathname.endsWith(routes.dossier_police)) {
            service = 'Policía';
            setTabIndex(4);
        } else if (location.pathname.endsWith(routes.dossier_health)) {
            service = 'Sanitario';
            setTabIndex(5);
        } else if (location.pathname.endsWith(routes.dossier_rgpd)) {
            setTabIndex(6);
        } else if (location.pathname.endsWith(routes.dossier_interventions)) {
            showSave = false;
            showReset = false;
            setTabIndex(7);
        } else if (location.pathname.endsWith(routes.dossier_files)) {
            showSave = false;
            showReset = false;
            showUpload = true;
            setTabIndex(8);
        } else {
            setTabIndex(0);
        }
        setService(service);
        setFlags({
            canAddIntervention: dossierExists && service !== '',
            canSave: showSave,
            canReset: showReset,
            canDelete: showDelete,
            canUpload: showUpload,
        });
    };

    useEffect(() => updateFlags(), [location.pathname, uuid, auth.user]);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const context = { 
        dossier, 
        main, setMain, 
        sociodemographic, setSociodemographic, 
        socialServices, setSocialServices, 
        legalServices, setLegalServices, 
        healthServices, setHealthServices, 
        securityServices, setSecurityServices, 
        policeServices, setPoliceServices, 
        rgpd, setRGPD, 
        interventionUUID, setInterventionUUID,
        showDragArea
    };

    const [showAlert, setShowAlert] = useState('');
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setShowAlert('');
    };

    const handleNavigate = (to) => {
        navigate(to, { replace: true });
    }

    return (
        <MainFrame>
            <Prompt when={changed} exception={RegExp(`^${routes.getDossierRoute(uuid)}.*`)} message={t('prompt.unsaved_dossier')} />
            <Intervention uuid={interventionUUID} setUUID={setInterventionUUID} dossier={dossier.uuid} service={service}/>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} noValidate>
                {uuid === 'new' && (<Title>{t('label.dossier_add')}</Title>)}
                <Grid container spacing={2} sx={{ pb: 2 }}>
                    {
                        uuid !== 'new' && false && (
                            <Grid item xs={12}>
                                <CopyTextField value={uuid} label={t('label.uuid')} disabled fullWidth />
                            </Grid>
                        )
                    }
                    <Grid item xs={12} sm={6} >
                        <CustomTextField value={name} setValue={setName} label={t('label.name')} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField value={surname} setValue={setSurname} label={t('label.surname')} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField value={ni} setValue={setNi} label={t('label.ni')} fullWidth />
                    </Grid>
                </Grid>
                <Tabs value={tabIndex} sx={{ pb: 2 }} variant="scrollable" scrollButtons="auto">
                    <Tab onClick={() => handleNavigate(routes.getDossierRoute(uuid, routes.dossier_main))} label={t('dossier.main_tab')} {...a11yProps(0)} />
                    <Tab onClick={() => handleNavigate(routes.getDossierRoute(uuid, routes.dossier_sociodemographic))} label={t('dossier.sociodemographic_tab')} {...a11yProps(0)} />
                    <Tab onClick={() => handleNavigate(routes.getDossierRoute(uuid, routes.dossier_social))} label={t('dossier.social_services_tab')} {...a11yProps(0)} />
                    <Tab onClick={() => handleNavigate(routes.getDossierRoute(uuid, routes.dossier_security))} label={t('dossier.security_services_tab')} {...a11yProps(1)} />
                    <Tab onClick={() => handleNavigate(routes.getDossierRoute(uuid, routes.dossier_police))} label={t('dossier.police_services_tab')} {...a11yProps(1)} />
                    <Tab onClick={() => handleNavigate(routes.getDossierRoute(uuid, routes.dossier_health))} label={t('dossier.health_services_tab')} {...a11yProps(1)} />
                    <Tab onClick={() => handleNavigate(routes.getDossierRoute(uuid, routes.dossier_rgpd))} label={t('dossier.RGPD_tab')} {...a11yProps(1)} />
                    <Tab onClick={() => handleNavigate(routes.getDossierRoute(uuid, routes.dossier_interventions))} label={t('dossier.interventions_tab')} {...a11yProps(1)} />
                    {/* <Tab onClick={() => handleNavigate(routes.getDossierRoute(uuid, routes.dossier_files))} label={t('dossier.files_tab')} {...a11yProps(1)} /> */}
                </Tabs>

                <Outlet context={context} />
            </Paper>

            <SpeedDial
                ariaLabel=""
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
            >
                {flags.canUpload && <SpeedDialAction 
                    icon={<FileUploadIcon/>}
                    tooltipTitle={t('tooltip.upload')}
                    onClick={handleStartUpload} />}

                {flags.canReset && <SpeedDialAction 
                    icon={<RestartAltIcon/>}
                    tooltipTitle={t('tooltip.reset')}
                    disabled={!changed}
                    onClick={handleRestart} />}

                {flags.canSave && <SpeedDialAction 
                    icon={<SaveIcon />}
                    tooltipTitle={t('tooltip.save')}
                    disabled={!changed}
                    onClick={handleSaveDossier} /> }
                    
                {flags.canAddIntervention && <SpeedDialAction 
                    icon={<AddCommentIcon />}
                    tooltipTitle={t('tooltip.add_intervention')}
                    onClick={handleAddIntervention} /> }

                {flags.canDelete && <SpeedDialAction 
                    icon={<DeleteIcon />}
                    tooltipTitle={t('tooltip.delete')}
                    onClick={handleDeleteDossier} /> }
            </SpeedDial>

            <Dialog
                open={!!deletedDossier.uuid}
                onClose={handleDeleteDossierReject}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('dialog.delete_dossier_title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('dialog.delete_dossier_desc')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDossierReject}>{t('dialog.button_no')}</Button>
                    <Button onClick={handleDeleteDossierAccept} autoFocus>{t('dialog.button_yes')}</Button>
                </DialogActions>
            </Dialog>

            <DropzoneDialog open={showDragArea} onClose={() => setShowDragArea(false)} onSave={handleSaveDragArea} dropzoneText={t('label.drag_file')} previewText={t('label.drag_preview')}/>

            {uploadProgress >= 0 && <LinearProgress variant="determinate" value={uploadProgress} />}

            <Snackbar open={!!showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                    {showAlert}
                </Alert>
            </Snackbar>
        </MainFrame>
    );
}