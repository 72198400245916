import {
  Navigate,
} from "react-router-dom";
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

import routes from "../routes.js";
import {
  useAuth,
} from "../hooks/useAuth.js";

export function E404() {
  const auth = useAuth();

  return <Navigate to={routes.home} />;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h1" sx={{ mt: 1 }}>
        404
      </Typography>
      <Typography variant="h6" sx={{ mt: 1 }}>
        The page you’re looking for doesn’t exist.
      </Typography>
      <Button variant="contained" sx={{ mt: 1 }} onClick={app.reset}>
        Back Home
      </Button>
    </Box>
  );
}