import { 
    useOutletContext 
} from "react-router-dom";
import {
    useTranslation,
} from 'react-i18next';

import {
    Box,
    Container,
    Grid,
} from '@mui/material';

import {
    renderFormElement,
} from './renderFormElement.js'

export function Legal() {

    const { t } = useTranslation();
    const {legalServices, setLegalServices} = useOutletContext();

    const userData = [
        {label: 'legal.legalSituation', key: 'legalSituation', type: 'title', width: 12},
        {label: 'legal.protectionOrder', key: 'protectionOrder', type: 'bool', width: 6},
        {label: 'legal.convition', key: 'convition', type: 'bool', width: 6},
        {type: 'divider', key: 'divider_0'},
        {label: 'legal.custodianPolicy', key: 'custodianPolicy', type: 'text', width: 12},
        {label: 'legal.freeJustice', key: 'freeJustice', type: 'text', width: 12},
        {label: 'legal.OAVD', key: 'OAVD', type: 'text', width: 12},
        {label: 'legal.IMELGA', key: 'IMELGA', type: 'text', width: 12},
    ];

    const setValueHandler = (key) => {
        return (value) => {
            legalServices[key] = value;
            setLegalServices(JSON.parse(JSON.stringify(legalServices)));
        };
    }

    return (
        <>
            <Box sx={{ border: 0, borderColor: (theme) => theme.palette.grey[100], }} >
                <Grid container spacing={2} sx={{ pb: 2 }}>
                    {userData.map((entry) => (
                        <Grid item xs={12} sm={entry.width || 12 } key={entry.key} >
                            {renderFormElement(t, legalServices, entry, setValueHandler)}
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
}