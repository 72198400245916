export const home = '/';
export const profile = '/profile';
export const users = '/users';
export const suggestions = '/suggestions';
export const suggestion = '/suggestions/:uuid';
export const login = '/login';
export const logout = '/logout';
export const signup = '/signup/:token';
export const dossiers = '/dossier';
export const dossier = '/dossier/:uuid';
export const dossier_main = 'main';
export const dossier_sociodemographic = 'sociodemographic';
export const dossier_social = 'social';
export const dossier_legal = 'legal';
export const dossier_health = 'health';
export const dossier_security = 'security';
export const dossier_police = 'police';
export const dossier_rgpd = 'rgpd';
export const dossier_interventions = 'interventions';
export const dossier_files = 'files';
export const interventions = '/interventions';
export const intervention = '/interventions/:uuid';

export const getDossierRoute = (uuid, sub = '') => {
    return `${dossier.replace(':uuid', uuid)}/${sub}`;
};

export const dossierExp = RegExp(dossier.replace(":uuid", "([^\\\/]*)"));

export const getInterventionRoute = (uuid) => {
    return `interventions/${uuid}`;
};

export const interventionExp = RegExp(intervention.replace(":uuid", "([^\\\/]*)"));

export const getSuggestionRoute = (uuid, sub = '') => {
    return `${suggestion.replace(':uuid', uuid)}/${sub}`;
};

export const suggestionExp = RegExp(suggestion.replace(":uuid", "([^\\\/]*)"));

export default {
    home,
    profile,
    users,
    suggestions,
    suggestion,
    suggestionExp,
    login,
    logout,
    signup,
    dossiers,
    dossier,
    dossierExp,
    dossier_main,
    dossier_sociodemographic,
    dossier_social,
    dossier_legal,
    dossier_health,
    dossier_security,
    dossier_police,
    dossier_rgpd,
    dossier_interventions,
    dossier_files,
    interventions,
    intervention,
    interventionExp,
    getDossierRoute,
    getInterventionRoute,
    getSuggestionRoute,
};