import {
    post,
    request,
    remove,
} from './request.js'

export const getSuggestions = async (query) => {
    let res = await request(`/suggestions/?${Object.entries(query).map(([key, value]) => value ? `${key}=${value}` : '').filter(x => !!x).join('&')}`);
    if (res.status === 200)
        return await res.json();
    throw new Error(res.status);
};

export const getSuggestion = async (uuid) => {
    let res = await request(`/suggestions/${uuid}`);
    if (res.status === 200)
        return await res.json();
    throw new Error(res.status);
};

export const addSuggestion = async (suggestion) => {
    let res = await post('/suggestions', suggestion);
    if (res.status === 201)
        return await res.json();
    throw new Error(res.status);
};

export const deleteSuggestion = async (uuid) => {
    let res = await remove(`/suggestions/${uuid}`);
    if (res.status === 200)
        return await res.json();
    throw new Error(res.status);
};