import {
    post,
    request,
    remove,
} from './request.js'

export const getInterventions = async (query) => {
    let res = await request(`/interventions/?${Object.entries(query).map(([key, value]) => value ? `${key}=${value}` : '').filter(x => !!x).join('&')}`);
    if (res.status === 200)
        return await res.json();
    throw new Error(res.status);
};

export const getIntervention = async (uuid) => {
    let res = await request(`/interventions/${uuid}`);
    if (res.status === 200)
        return await res.json();
    throw new Error(res.status);
};

export const addIntervention = async (intervention) => {
    let res = await post('/interventions', intervention);
    if (res.status === 201)
        return await res.json();
    throw new Error(res.status);
};

export const deleteIntervention = async (uuid) => {
    let res = await remove(`/interventions/${uuid}`);
    if (res.status === 200)
        return await res.json();
    throw new Error(res.status);
};

export const updateIntervention = async (intervention) => {
    let res = await post(`/interventions/${intervention.uuid}`, intervention);
    if (res.status === 200)
        return await res.json();
    throw new Error(res.status);
};