import {
    useEffect,
    useState,
} from 'react';
import {
    useTranslation,
} from 'react-i18next';

import {
    Button,
    Grid,
    Paper,
    TextField,
} from '@mui/material';
import {
    PersonAdd as PersonAddIcon,
} from '@mui/icons-material';

import {
    CopyTextField,
    MainFrame,
    Title,
    UserList,
} from "../components";

import routes from "../routes.js";

import {
    issueSignUpToken,
} from '../services/auth.js';
import {
    validateEmail,
} from '../services/validator.js'
import {
    useAuth,
} from "../hooks/useAuth";

export function Users() {

    const auth = useAuth();
    const {t} = useTranslation();

    const [signupURL, setSignupURL] = useState('');
    const [tokenEmail, setTokenEmail] = useState('');
    const [tokenEmailValid, setTokenEmailValid] = useState("");

    const validateTokenEmail = () => {
        if (tokenEmail !== '')
            setTokenEmailValid(validateEmail()(tokenEmail));
        else
            setTokenEmailValid('');
    };
    
    useEffect(validateTokenEmail, [tokenEmail]);

    let handleIssueToken = async function (event) {
        event.preventDefault();
        try {
            const token = await issueSignUpToken(tokenEmail);
            const base = window.location.href.replace(/\/[^\/]+$/,'');
            setSignupURL(`${base}${routes.signup.replace(':token', token.token)}`);
            setTokenEmail('');
        } catch (e) {
            if (e.message === '401')
                auth.requestLogin();
        }
    }
    
    return (
        <MainFrame>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <UserList/>
                </Grid>
                <Grid item xs={12} hidden={auth.user.role !== 'admin'}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} component="form" onSubmit={handleIssueToken} noValidate>
                        <Title>{t('label.issue_token')}</Title>
                        <TextField value={tokenEmail} error={tokenEmailValid !== ""} helperText={tokenEmailValid} onChange={(e) => setTokenEmail(e.target.value)} name="name" label="e-mail" variant="outlined" fullWidth required sx={{ mt: 2 }}/>
                        <CopyTextField value={signupURL} name="url" label="URL" variant="outlined" fullWidth sx={{ mt: 2 }} disabled></CopyTextField>
                        <Button variant="outlined" disabled={tokenEmailValid !== "" || tokenEmail === ""} endIcon={<PersonAddIcon />} type="submit" sx={{ mt: 2 }}>{t('label.issue')}</Button>
                    </Paper>
                </Grid>
            </Grid>
        </MainFrame>
    );
}