import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

function Message(props) {
  const {variant = "body1"} = props;
  return (
    <Typography color="text.primary" variant="body1">
      {props.children}
    </Typography>
  );
}

Message.propTypes = {
  children: PropTypes.node,
};

export default Message;