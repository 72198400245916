import dayjs from 'dayjs';
import 'dayjs/locale/es';

import {
    useEffect,
    useState,
} from 'react';
import {
    useTranslation,
} from 'react-i18next';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
} from '@mui/material';
import {
    Delete as DeleteIcon,
    Save as SaveIcon,
} from '@mui/icons-material';

import { 
    dbToDayjs,
    dayjsToDb,
} from "../services/date.js";

import {
    MainFrame,
} from "../components";
import {  
    addSuggestion,
    deleteSuggestion,
    getSuggestion,
} from "../services/suggestions.js";
import {
    useAuth,
} from "../hooks/useAuth";
import routes from "../routes";

export function Suggestion() {
    
    const { uuid } = useParams();
    const location = useLocation();
    const auth = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [suggestion, setSuggestion] = useState({});
    const [date, setDate] = useState(0);
    const [incidence, setIncidence] = useState('');
    const [action, setAction] = useState('');

    const loadState = () => {
        setDate(suggestion.date || '');
        setIncidence(suggestion.incidence || '');
        setAction(suggestion.action || '');
    };
    
    const loadSuggestion = async () => {
        try {
            if (uuid !== 'new') {
                let data = await getSuggestion(uuid);
                setSuggestion(data);
            } else {
                setSuggestion({
                    date: dayjsToDb(dayjs()),
                });
            }
        } catch (e) {
            console.error("e", e)
            if (e.message === '401')
               auth.requestLogin();
            else
                setSuggestion({});
        }
    }

    const load = () => {
        loadSuggestion();
    }

    useEffect(() => loadState(), [suggestion]);
    useEffect(() => load(), []);
    useEffect(() => load(), [uuid]);
    
    const [flags, setFlags] = useState({});
    
    const updateFlags = () => {
        setFlags({
            canSave: uuid === 'new',
            canDelete: auth.user.role === "admin" && uuid !== 'new',
        });
    };

    useEffect(() => updateFlags(), [location.pathname, uuid, auth.user]);
    const handleSaveSuggestion = async () => {
        let data = {
            date, 
            incidence,
            action,
        };
        try {
            let res = await addSuggestion(data);
            navigate(routes.getSuggestionRoute(res.uuid), { replace: true });
        } catch (e) {
            if (e.message === '401')
               auth.requestLogin();
            else
                console.error("error", e);
        }
    };

    const handleDeleteSuggestion = async () => {
        try {
            await deleteSuggestion(uuid);
            navigate(routes.suggestions, { replace: true });
        } catch (e) {
            if (e.message === '401')
               auth.requestLogin();
            else
                console.error("error", e);
        }
    };
    
    return (
        <MainFrame>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                <Paper sx={{ display: 'flex', flexDirection: 'column' }} >
                    <FormControl fullWidth sx={{ p: 1, mt: 1 }}>
                        <InputLabel sx={{ p: 1 }} htmlFor="date-input">{t('label.date')}</InputLabel>
                        <OutlinedInput id="date-input" value={dbToDayjs(date).format('DD/MM/YYYY hh:mm:ss')} label={t('label.date')} readOnly={true} startAdornment={<InputAdornment position="start"></InputAdornment>} />
                    </FormControl>
                    <FormControl fullWidth sx={{ p: 1, mt: 1 }}>
                        <InputLabel sx={{ p: 1 }} htmlFor="incidence-input">{t('label.incidence')}</InputLabel>
                        <OutlinedInput id="incidence-input" value={incidence} onChange={(e) => setIncidence(e.target.value)} multiline={true} rows={4} label={t('label.incidence')} readOnly={!flags.canSave} startAdornment={<InputAdornment position="start"></InputAdornment>} />
                    </FormControl>
                    <FormControl fullWidth sx={{ p: 1, mt: 1 }}>
                        <InputLabel sx={{ p: 1 }} htmlFor="action-input">{t('label.action')}</InputLabel>
                        <OutlinedInput id="action-input" value={action} onChange={(e) => setAction(e.target.value)} multiline={true} rows={4} label={t('label.action')} readOnly={!flags.canSave} startAdornment={<InputAdornment position="start"></InputAdornment>} />
                    </FormControl>
                </Paper>
            </Paper>

            <SpeedDial
                ariaLabel=""
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
            >
                {flags.canSave && <SpeedDialAction 
                    icon={<SaveIcon />}
                    tooltipTitle={t('tooltip.save')}
                    onClick={handleSaveSuggestion} />}

                {flags.canDelete && <SpeedDialAction 
                    icon={<DeleteIcon />}
                    tooltipTitle={t('tooltip.delete')}
                    onClick={handleDeleteSuggestion} /> }

            </SpeedDial>
        </MainFrame>
    );
}