import { 
    useEffect,
    useState,
} from "react";
import {
    InputAdornment,
    TextField,
    Tooltip,
    useMediaQuery,
} from '@mui/material';
import { 
    useTheme,
} from '@mui/material/styles';


export function CustomTextField(props) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const {onChange, value = '', setValue, setIsValid, validator = null, error = '', buttons = [], ...innerProps} = props;

    const [valueInternal, setValueInternal] = useState('');
    const [errorInternal, setErrorInternal] = useState(true);
    const [helperTextInternal, setHelperText] = useState('');

    const handleOnChange = (e) => {
        if (!!onChange)
            onChange(e);
        if (!!setValue)
            setValue(e.target.value);
        setValueInternal(e.target.value);
    };

    const validate = () => {
        let helperText = !!validator ? validator(valueInternal) : '';
        if (!helperText) {
            if (!!setIsValid)
                setIsValid(true);
            setErrorInternal(!!error);
            setHelperText(error);
            return;
        }
        if (!!setIsValid)
            setIsValid(false);
        setErrorInternal(true);
        setHelperText(helperText);
    };

    useEffect(validate, [valueInternal, error]);
    useEffect(() => setValueInternal(value), [value]);

    const [focused, setFocused] = useState(false);
    if (isMobile) {
        return (
            <TextField {...innerProps} 
                value={valueInternal} 
                error={errorInternal} 
                helperText={focused && helperTextInternal} 
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChange={handleOnChange}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>
                            {buttons}
                        </InputAdornment>
                    }}
            />
        );
    }
                
    return (
        <Tooltip 
            title={helperTextInternal}
        >
            <TextField {...innerProps} 
                value={valueInternal} 
                error={errorInternal} 
                // helperText={helperTextInternal} 
                onChange={handleOnChange}

                InputProps={{
                    endAdornment: <InputAdornment position='end'>
                        {buttons}
                    </InputAdornment>
                }}
            />
        </Tooltip>
    );
}