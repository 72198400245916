import * as React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function DataTreatment(props) {
  const {variant = "body1"} = props;
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
  <div>
    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header"><strong>Responsable do Tratamento</strong></AccordionSummary>
      <AccordionDetails>
        <address>
          <div>Universidade de Vigo - CIF Q8650002B</div>
          <div>Campus universitario Lagoas-Marcosende s/n</div>
          <div>Edificio Exeria</div>
          <div>36310 Vigo (Pontevedra)</div>
          <div><a href="tel:+34986812000">+34 986 812 000</a></div>
          <div><a href="mailto:informacion@uvigo.gal">informacion@uvigo.gal</a></div>
          <div><a href="https://www.uvigo.gal/">https://www.uvigo.gal/</a></div>
        </address>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header"><strong>Delegada de Protección de Datos</strong></AccordionSummary>
      <AccordionDetails>
        <div>Ana Garriga Domínguez</div>
        <address>
          <div>Facultade de Dereito</div>
          <div>Campus universitario As Lagoas s/n</div>
          <div>32004 Ourense</div>
          <div><a href="tel:+34988368834">+34 988 368 834</a></div>
          <div><a href="mailto:dpd@uvigo.gal">dpd@uvigo.gal</a></div>
        </address>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header"><strong>Finalidade</strong></AccordionSummary>
      <AccordionDetails>
        <ul>
          <li>O desenvolvemento das funcións relacionadas co principio de igualdade entre mulleres e homes en todos os ámbitos universitarios e a aplicación do Protocolo marco de actuación para a prevención e sanción do acoso sexual e por razón de sexo da Universidade de Vigo. Resolución de procedementos de acoso ou violencia de xénero.</li>
          <li>Protocolo de actuación para cambiar de nome e de xénero de uso común das persoas transexuais, transxénero e intersexuais na Universidade de Vigo.</li>
          <li>Xestión administrativa das Xornadas e Congresos que se organicen. Fins de arquivo en interese público, fins de investigación científica e histórica e fins estatísticos</li>
        </ul>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header"><strong>Lexitimación</strong></AccordionSummary>
      <AccordionDetails>
        <ul>
          <li>Regulamento xeral de protección de datos: artigo 6.1. b) Tratamento necesario para a execución dun contrato no que o interesado é parte ou para a aplicación a petición deste de medidas precontractuais.</li>
          <li>Regulamento xeral de protección de datos: artigo 6.1.e) Tratamento necesario para o cumprimento dunha misión realizada en interese público ou no exercicio de poderes públicos conferidos ao responsable do tratamento</li>
          <li>Lei orgánica 2/2023, de 22 de marzo, do sistema universitario.</li>
          <li>Estatutos da Universidade de Vigo.</li>
          <li>Lei orgánica 3/2007, de 22 de marzo, para a igualdade efectiva de mulleres e homes.</li>
          <li>Pacto de Estado contra a Violencia de Xénero.</li>
          <li>Lei 7/2004, de 16 de xullo, galega para a igualdade de mulleres e homes.</li>
          <li>Lei 11/2007 , de 27 de xullo, galega para a prevención e o tratamento integral da violencia de xénero.</li>
          <li>Lei 2/2014, de 14 de abril, pola igualdade de trato e a non discriminación de lesbianas, gays, transexuais, bisexuais e intersexuais en Galicia.</li>
        </ul>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5-content" id="panel5-header"><strong>Interesados</strong></AccordionSummary>
      <AccordionDetails>
        <ul>
          <li>Persoal docente e investigador</li>
          <li>Persoal de Administración e Servizos</li>
          <li>Estudantes</li>
          <li>Dirección do evento</li>
          <li>Seguridade do evento</li>
          <li>Policía e corpos de seguridade pública</li>
          <li>Traballadores sociais</li>
          <li>Protección do menor</li>
          <li>Servicios Sanitarios</li>
          <li>Xunta de Galicia</li>
        </ul>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6-content" id="panel6-header"><strong>Categorías de datos</strong></AccordionSummary>
      <AccordionDetails>
        Tipos de datos xestionados no tratamento:
        <ul>
          <li><strong>Expediente:</strong> Rango de Idade, Estado civil, Vínculo coas fillas/os, Núcleo de convivencia, Tipo de vivenda, Réxime de tenencia de vivenda, Nivel de ingresos, Orixe dos ingresos, Situación laboral, Nivel educativo formal, Prestación asignada, Tipo de violencias detectadas pola usuaria, Interseccionalidade, Vínculo co agresor</li>
          <li><strong>Datos Sociodemográficos:</strong> Data de nacemento, Profesión, Enderezo, Teléfono, Enderezo electrónico, Se ten parella, Se é unha muller Migrante, Fillos/as, Se ten fillos en común co agresor</li>
          <li><strong>Psico-social:</strong> Punto lila asignado, Outros Servizos sociais contactados</li>
          <li><strong>Policía:</strong> Se existen Denuncia Previa, Orde de Protección ou Caso en VioGen</li>
          <li><strong>Servizos Sanitarios:</strong> Tipo de seguro público/privado, Aseguradora, médica/o de referencia en atención primaria</li>
          <li><strong>Intervencións:</strong> Relato das intervencións realizadas vencelladas co expediente.</li>
        </ul>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7-content" id="panel7-header"><strong>Destinatarios</strong></AccordionSummary>
      <AccordionDetails>
        Os datos non serán cedidos, excepto nos casos previstos legalmente cando sexa necesario para o cumprimento dunha obriga legal aplicable ao responsable do tratamento.
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8-content" id="panel8-header"><strong>Dereitos</strong></AccordionSummary>
      <AccordionDetails>
        <ul>
          <li>Dereito de aceso</li>
          <li>Dereito de rectificación</li>
          <li>Dereito de supresión</li>
          <li>Dereito á limitación do tratamiento</li>
          <li>Dereito á portabilidade</li>
          <li>Dereito de oposición</li>
        </ul>
        Máis detalle na <a href="https://www.uvigo.gal/es/universidad/informacion-institucional/proteccion-datos">páxina de protección de datos da Universidade de Vigo</a>
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9-content" id="panel9-header"><strong>Transferencias internacionais previstas</strong></AccordionSummary>
      <AccordionDetails>
        Non se prevén
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel10-content" id="panel10-header"><strong>Prazo de supresión</strong></AccordionSummary>
      <AccordionDetails>
        Consérvanse durante o tempo necesario para cumprir coa finalidade para a que se recolleron e para determinar as posibles responsabilidades que se puideran derivar de dita finalidade e do tratamento de datos. Será de aplicación o disposto na normativa de arquivo e documentación
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel11-content" id="panel11-header"><strong>Medidas de seguridade</strong></AccordionSummary>
      <AccordionDetails>
        É de aplicación o disposto no Real Decreto 311/2022, de 3 de maio, polo que se regula o Esquema Nacional de Seguridade.
      </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel12-content" id="panel12-header"><strong>Estrutura responsable da xestión da actividade de tratamento</strong></AccordionSummary>
      <AccordionDetails>
        <address>
          <div><a href="https://www.uvigo.gal/es/estudiar/organizacion-academica/centros/socioloxia-ciencia-politica-administracion-filosofia/">Departamento de Socioloxía, Ciencia Política e Filosofía</a></div>
          <div>Campus Lagoas-Marcosende</div>
          <div>36310 Vigo (Pontevedra)</div>
          <div><a href="tel:+34986130213">+34 986 130 213</a></div>
          <div><a href="mailto:udeconomicas@uvigo.es">udeconomicas@uvigo.es</a></div>
        </address>
      </AccordionDetails>
    </Accordion>
  </div>
  );
}

DataTreatment.propTypes = {
  children: PropTypes.node,
};

export default DataTreatment;