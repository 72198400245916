import {
    Fragment,
} from 'react';
import {
    useNavigate,
} from "react-router-dom";
import {
  useTranslation,
 } from 'react-i18next';
import {
    IconButton,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Drawer as MuiDrawer,
    Toolbar,
} from '@mui/material';
import { 
    styled,
} from '@mui/material/styles';
import {
    AddComment as AddCommentIcon,
    Reviews as ReviewsIcon,
    ChevronLeft as ChevronLeftIcon,
    People as PeopleIcon,
    PersonAdd as PersonAddIcon,
    PersonSearch as PersonSearchIcon,
    RateReview as RateReviewIcon,
} from '@mui/icons-material';

import { 
    drawerWidth
} from "../settings.js";
import routes, {
    getDossierRoute,
    getSuggestionRoute,
} from "../routes";

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })
(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(0),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export function Drawer({ keepOpen, drawerOpen, toggleDrawer}) 
{
    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path, { replace: true });
        toggleDrawer();
    }

    return (
        <StyledDrawer variant="permanent" open={drawerOpen || keepOpen}>
            <Toolbar sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1]}}>
                <IconButton onClick={toggleDrawer} sx={{ ...(keepOpen && { display: 'none' }) }}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
                <Fragment key='main-section'>
                    <ListSubheader component="div" inset>
                        {t('label.dossiers')}
                    </ListSubheader>
                    <ListItemButton onClick={() => handleClick(getDossierRoute("new"))} selected={location.pathname === getDossierRoute("new") || location.pathname === routes.home}>
                        <ListItemIcon><PersonAddIcon /></ListItemIcon>
                        <ListItemText primary={t('label.dossier_add')} />
                    </ListItemButton>

                    <ListItemButton onClick={() => handleClick(routes.dossiers)} selected={(location.pathname.startsWith(routes.dossiers) && !location.pathname.endsWith("/new/"))}>
                        <ListItemIcon><PersonSearchIcon /></ListItemIcon>
                        <ListItemText primary={t('label.dossier_search')} />
                    </ListItemButton>
                    
                    <ListItemButton onClick={() => handleClick(routes.interventions)} selected={location.pathname.startsWith(routes.interventions)}>
                        <ListItemIcon><AddCommentIcon /></ListItemIcon>
                        <ListItemText primary={t('label.intervention_search')} />
                    </ListItemButton>

                </Fragment>
            </List>
        </StyledDrawer>
    );
}