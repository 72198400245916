
import dayjs from 'dayjs';
import 'dayjs/locale/es';

import {
    useEffect,
    useState,
    Fragment,
} from 'react';
import {
    useTranslation,
} from 'react-i18next';
import {
    Alert,
    Autocomplete,
    Box,
    Fab,
    Grid,
    Paper,
    Snackbar,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
} from '@mui/material';
import { 
    DatePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';
import { 
    AdapterDayjs,
} from '@mui/x-date-pickers/AdapterDayjs';
import {
    ManageSearch as ManageSearchIcon,
} from '@mui/icons-material';

import routes from "../routes";
import {
    CustomTextField,
    Intervention,
    MainFrame,
    Title,
} from "../components";
import {  
    getInterventions,
} from "../services/interventions.js";
import { 
    getAutocomplete,
} from "../services/autocomplete.js";
import {
    useAuth,
} from "../hooks/useAuth";
import { 
    useResolver,
} from "../hooks/useResolver.js";
import * as DateUtils from "../services/date.js";

export function Interventions() {
    
    const auth = useAuth();
    const resolver = useResolver();
    const { t } = useTranslation();

    const [service, setService] = useState("");
    const [professional, setProfessional] = useState("");
    const [dossier, setDossier] = useState("");
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [interventions, setInterventions] = useState([]);
    const [interventionUUID, setInterventionUUID] = useState();

    const [autoCompleteDossiers, setAutoCompleteDossiers] = useState([]);
    const [autoCompleteUsers, setAutoCompleteUsers] = useState([]);

    const loadAutofill = async () => {
        try {
            const auto = await getAutocomplete();
            setAutoCompleteDossiers(auto.dossiers);
            setAutoCompleteUsers(auto.users);
        } catch (e) {
            if (e.message == '404')
                setShowAlert(t('alert.get_autocomplete_failed'));
            else if (e.message === '401')
                auth.requestLogin();
        }
    }

    const load = () => {
        loadAutofill();
    }

    useEffect(() => load(), []);

    const handleSearch = async (e) => {
        e.preventDefault();
        try {
            let query = {service, professional, dossier};
            if (dateFrom !== null || dateTo !== null) {
                let from = dateFrom;
                let to = dateTo;
                if (dateFrom === null)
                    from = DateUtils.dayjsToDb(dayjs('1900-01-01 00:00'));
                if (dateTo === null)
                    to = DateUtils.dayjsToDb(dayjs('2200-01-01 00:00'));
                query.date = `range(${from},${to})`;
            }
            let interventions = await getInterventions(query);
            await resolver.resolve('users', interventions.map(int => int.professional));
            await resolver.resolve('dossiers', interventions.map(int => int.dossier));
            setInterventions(interventions);
        } catch (e) {
            console.error("error", e);
            if (e.message == '404')
                setShowAlert(t('alert.get_interventions_failed'));
            else if (e.message === '401')
                auth.requestLogin();
        }
    };

    const [showAlert, setShowAlert] = useState('');
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setShowAlert('');
    };

    return (
        <MainFrame>
            <Intervention uuid={interventionUUID} setUUID={setInterventionUUID}/>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                    <Title>{t('label.intervention_search')}</Title>
                    <Grid container spacing={2} sx={{ pb: 2 }}>
                        <Grid item xs={12} sm={6} >
                            <CustomTextField value={service} setValue={setService} label={t('label.service')} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            {/* <CustomTextField value={professional} setValue={setProfessional} label={t('label.professional')} fullWidth /> */}

                            <Autocomplete disablePortal options={autoCompleteUsers} fullWidth onChange={(k, v) => setProfessional(v?.uuid)} renderInput={(params) => 
                                <TextField {...params} label={t('label.professional')} />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {/* <CustomTextField value={dossier} setValue={setDossier} label={t('label.dossier')} fullWidth /> */}
                            <Autocomplete disablePortal options={autoCompleteDossiers} fullWidth onChange={(k, v) => setDossier(v?.uuid)} renderInput={(params) => 
                                <TextField {...params} label={t('label.dossier')} />}
                            />
                        </Grid> 
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DatePicker value={dateFrom ? DateUtils.dbToDayjs(dateFrom) : null} onChange={value => setDateFrom(DateUtils.dayjsToDb(value))} label={t('label.dateFrom')} slotProps={{ textField: { fullWidth: true } }} /> 
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DatePicker value={dateTo ? DateUtils.dbToDayjs(dateTo) : dayjs().startOf('day')} onChange={value => setDateTo(DateUtils.dayjsToDb(value))} label={t('label.dateTo')} slotProps={{ textField: { fullWidth: true } }}/>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', '& > :not(style)': { m: 1 } }}>
                                <Fab color="primary" disabled={false && !searchAllowed} onClick={handleSearch}>
                                    <ManageSearchIcon />
                                </Fab>
                            </Box>
                        </Grid>
                    </Grid>
                <Fragment>
                    <Table size="small"  sx={{ mt: 2, mb: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>{t('label.date')}</TableCell>
                                <TableCell>{t('label.service')}</TableCell>
                                <TableCell>{t('label.professional')}</TableCell>
                                <TableCell>{t('label.dossier')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {interventions.map((row, index) => (
                            <TableRow key={row.uuid} onClick={() => setInterventionUUID(row.uuid)}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{DateUtils.dbToDayjs(row.date).format("DD/MM/YYYY")}</TableCell>
                                <TableCell>{row.service}</TableCell>
                                <TableCell>{resolver.resolveUser(row.professional)}</TableCell>
                                <TableCell>{resolver.resolveDossier(row.dossier)}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </Fragment>
            </Paper>
            <Snackbar open={!!showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                    {showAlert}
                </Alert>
            </Snackbar>
        </MainFrame>
    );
}