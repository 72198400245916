import {
    request,
    post,
} from './request.js'
import { 
    fixAvatar,
} from "./users.js";

export const ensureLogin = async function () {
    let res = await request("/user");
    if (res.status === 200) {
        let user = await res.json();
        return fixAvatar(user);
    }
    throw new Error(res.status);
};

export const login = async function (username, password, remember) {
    let res = await post("/login", { username, password, remember });
    if (res.status === 200) {
        let user = await res.json();
        return fixAvatar(user);
    }
    throw new Error(res.status);
};

export const logout = async function () {
    await request("/logout");
};

export const issueSignUpToken = async function(email) {
    let res = await post("/signuptokens", { email });
    if (res.status === 201) {
        let token = await res.json();
        return token;
    }
    throw new Error(res.status);
};