import {
    Fragment,
    useState,
} from 'react';
import {
    useTranslation,
} from 'react-i18next';

// import { styled } from '@mui/material/styles';

import {
    Avatar,
    Card,
    CardActionArea,
    CardActions,
    CardHeader,
    CardMedia,
    // CardContent,
    // Collapse,
    IconButton,
    ListItemIcon,
    Link,
    Menu,
    MenuItem,
    Paper,
    // Table,
    // TableBody,
    // TableCell,
    // TableRow,
} from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    MoreVert as MoreVertIcon,
 } from '@mui/icons-material';

// import {
//     red,
// } from '@mui/material/colors';

import {
    Delete as DeleteIcon,
} from '@mui/icons-material';

import {
    useAuth,
} from "../hooks/useAuth";

// const ExpandMore = styled((props) => {
//     const { expand, ...other } = props;
//     return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//     transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//     marginLeft: 'auto',
//     transition: theme.transitions.create('transform', {
//         duration: theme.transitions.duration.shortest,
//     }),
// }));

export const File = ({file, onDeleteFile}) => {
    const {t} = useTranslation();
    const auth = useAuth();

    // const [cardExpanded, setCardExpanded] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const menuOpen = Boolean(menuAnchorEl);

    const handleMenuOpen = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };
    
    const handleRemoveFile = () => {
        if (!!onDeleteFile)
            onDeleteFile(file);
        setMenuAnchorEl(null);
    };
    
    // const handleExpandClick = () => {
    //     setCardExpanded(!cardExpanded);
    // };
  
    const getImage = (file) => {
        let fileName = file.fileName.toLowerCase();
        if ([".gif", ".jpg", ".jpeg", ".jfif", ".pjpeg", ".pjp", ".png", ".svg", ".webp"].find(fmt => fileName.endsWith(fmt)))
            return file.url;
        if (fileName.endsWith(".doc") || fileName.endsWith(".docx") || fileName.endsWith(".odt"))
            return "/static/images/microsoft-word-icon.svg";
        if (fileName.endsWith(".pdf"))
            return "/static/images/PDF_file_icon.svg";
    }

    return (
        <>
            <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                    <MenuItem onClick={handleRemoveFile}>
                        <ListItemIcon><DeleteIcon/></ListItemIcon>
                        {t('action.remove_file')}
                    </MenuItem>
                </Menu>
            </Paper>
            <Card>
                <CardActionArea component={Link} target="_blank" href={file.url}>
                    <CardHeader 
                        // avatar={<Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" alt={user ? user.name : null} src={user ? user.avatar : null} ></Avatar>}
                        action={
                            <>
                                {/* <IconButton aria-label="settings" onClick={handleMenuOpen}><MoreVertIcon /></IconButton> */}
                                {/* <ExpandMore expand={cardExpanded} onClick={handleExpandClick} aria-expanded={cardExpanded} aria-label="show more">
                                    <ExpandMoreIcon />
                                </ExpandMore> */}
                            </>
                        }
                        // title={`${file.fileName}`}
                        subheader={file.fileName}
                        sx={{padding: 1, "text-align": "center"}}
                    />
                    <CardMedia
                        component="img"
                        height="128"
                        image={getImage(file)}
                        sx={{ height: 128, width: 128, "object-fit": "contain", margin: "auto"}}
                    />
                    {/* <Collapse in={cardExpanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Table size="small">
                                <TableBody>
                                        <TableRow>
                                            <TableCell>{t('label.email')}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('label.phone')}</TableCell>
                                            <TableCell>{user.phone}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('label.profession')}</TableCell>
                                            <TableCell>{user.profession}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('label.service')}</TableCell>
                                            <TableCell>{user.service}</TableCell>
                                        </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Collapse> */}
                    <CardActions>

                    </CardActions>
                </CardActionArea>
            </Card>
        </>
    );
};