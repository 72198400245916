import { 
  useState,
} from "react";
import {
 useTranslation,
} from 'react-i18next';
import {
  Avatar,
  Button,
  CssBaseline,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Container,
 } from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import {
  useAuth,
} from "../hooks/useAuth";
import { 
  Copyright,
  CustomTextField,
  PasswordTextField,
} from "../components";


export function Login() 
{
  const {t} = useTranslation();
  const [error, setError] = useState('');
  const [remember, setRemember] = useState(false);

  const auth = useAuth();

  const handleSubmit = async (event) => 
  {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      await auth.login(data.get('username'), data.get('password'), remember);
    } catch (e) {
      setError(t('login_failed'));
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }} >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
        {t('title.sign_in')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <CustomTextField error={error} margin="normal" required fullWidth id="username" label={t('label.username')} name="username" autoComplete="username" autoFocus />
          <PasswordTextField error={error} margin="normal" required fullWidth name="password" label={t('label.password')} type="password" id="password" autoComplete="current-password" />
          <FormControlLabel control={<Checkbox value="remember" color="primary" onChange={(evt) => setRemember(evt.target.checked)}/>} label={t('label.remember_me')} />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} >
            {t('button.sign_in')}
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
